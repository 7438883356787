import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  YMap,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapComponentsProvider,
  YMapListener,
  YMapMarker,
  YMapZoomControl,
  YMapControls,
} from "ymap3-components";
import {
  location as LOCATION,
  locationCloser,
  customizationArray,
} from "./MapHelpers";
import {
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from "react-bootstrap";
import MapPoint from "./../../../images/index/map-point.png";
import MapPointBlack from "./../../../images/index/map-point-black.png";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import throttle from "lodash.throttle";
import { isMobile } from "react-device-detect";

export const Map = ({
  zoom,
  selected,
  newPoint,
  setNewPoint,
  typeOfPoints,
  pointsCallback,
}: {
  zoom?: 10 | 20;
  selected?: number | undefined;
  newPoint?: { coordinates: [number, number]; tooltip: string };
  setNewPoint?: React.Dispatch<
    React.SetStateAction<
      | {
          coordinates: [number, number];
          tooltip: string;
        }
      | undefined
    >
  >;
  setDeliveryAddress?: React.Dispatch<React.SetStateAction<string>>;
  typeOfPoints?: "links" | "choices";
  pointsCallback?: any;
}) => {
  const [location, setLocation] = useState(
    zoom && zoom === 20 ? locationCloser : LOCATION
  );
  const [clickData, setClickData] = useState<any>();

  const { data, refetch } = useQuery({
    queryKey: ["delivery coordinates"],
    enabled: false,
    queryFn: () =>
      axios.get(
        `https://geocode-maps.yandex.ru/1.x/?apikey=5af93faa-7ddc-4d42-ad93-d93f6b01fd5a&geocode=${clickData.geometry.coordinates}&format=json`
      ),
  });

  useEffect(() => {
    if (clickData?.geometry && clickData?.geometry.coordinates && setNewPoint) {
      pointsCallback(3);
      setLocation({ center: clickData.geometry.coordinates, zoom: 13 });
    }
    refetch();
  }, [clickData]);

  useEffect(() => {
    data &&
      clickData?.geometry &&
      setNewPoint &&
      setNewPoint({
        coordinates: clickData.geometry.coordinates,
        tooltip: "here?",
      });
  }, [data]);

  const [showTooltip, setShowTooltip] = useState<1 | 2 | 3 | false>(false);

  const handleToggle = useCallback(
    throttle((number) => {
      setShowTooltip(number);
    }, 300),
    [] // Зависимости. Если ничего не меняется, throttle не будет пересоздаваться
  );

  const handleZoomChange = () => {
    // Скрыть подсказку при изменении масштаба
    setShowTooltip(false);
  };

  const handleOutsideClick = (event) => {
    const tooltips = document.querySelectorAll(".tooltip-content");
    const isClickInsideAnyTooltip = Array.from(tooltips).some((tooltip) =>
      tooltip.contains(event.target)
    );

    if (!isClickInsideAnyTooltip) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showTooltip]);

  useEffect(() => {
    switch (selected) {
      case 3:
        newPoint?.coordinates &&
          setLocation({ center: newPoint.coordinates, zoom: 15 });
        break;
      case 2:
        setLocation({ center: [30.486548, 59.915025], zoom: 13 });
        break;
      case 1:
        setLocation({ center: [30.260111, 59.990724], zoom: 13 });
        break;
      default:
        return;
    }
  }, [newPoint, selected]);
  try {
    return (
      <YMap location={location} theme="light" className="map-container">
        <YMapListener
          onClick={(data) => {
            setClickData(data?.entity);
          }}
        />
        <YMapDefaultFeaturesLayer />
        <YMapControls position="right">
          <YMapZoomControl />
        </YMapControls>
        <YMapMarker
          coordinates={[30.260111, 59.990724]}
          onClick={() => typeOfPoints === "choices" && pointsCallback(1)}
          zIndex={1200}
        >
          {
            <>
              <div
                className="map_image-container"
                style={{
                  display: isMobile ? 'block' : showTooltip === 1 ? "none" : "block",
                }}
              >
                <img
                  src={selected && selected !== 1 ? MapPointBlack : MapPoint}
                  alt="point"
                  className="map_image"
                  style={{ opacity: selected && selected !== 1 ? "0.7" : "1" }}
                  onClick={() => setShowTooltip(1)}
                />
              </div>
              {showTooltip === 1 && (
                <div className={"tooltip-content"}>
                  <img
                    src={selected && selected !== 1 ? MapPointBlack : MapPoint}
                    alt="point"
                    style={{
                      opacity: selected && selected !== 1 ? "0.7" : "1",
                    }}
                    onClick={() => setShowTooltip(false)}
                  />
                  <p className="title">БЦ “ГУЛЛИВЕР”</p>
                  <p>+7 (812) 317-68-15</p>
                  <p>Торфяная дорога 7Ф БЦ«Гулливер» 2 оф. 104</p>
                  <p>пн-вс: 09:00 - 21:00</p>
                  <button
                    onClick={() =>
                      window.open(
                        "https://yandex.ru/maps/org/gulliver_2/1405086736/?indoorLevel=1&ll=30.259690%2C59.989587&z=17.02",
                        "_blank"
                      )
                    }
                  >
                    ЯНДЕКС КАРТЫ
                  </button>
                  <div className={"tooltip-content_close"} onClick={() => setShowTooltip(false)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2295_89482)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.0026 0.835938C4.93999 0.835938 0.835938 4.93999 0.835938 10.0026C0.835938 15.0652 4.93999 19.1693 10.0026 19.1693C15.0652 19.1693 19.1693 15.0652 19.1693 10.0026C19.1693 4.93999 15.0652 0.835938 10.0026 0.835938ZM8.03293 6.97227C7.74004 6.67938 7.26517 6.67938 6.97227 6.97227C6.67938 7.26517 6.67938 7.74004 6.97227 8.03293L8.94194 10.0026L6.97227 11.9723C6.67938 12.2652 6.67938 12.74 6.97227 13.0329C7.26517 13.3258 7.74004 13.3258 8.03293 13.0329L10.0026 11.0633L11.9723 13.0329C12.2652 13.3258 12.74 13.3258 13.0329 13.0329C13.3258 12.74 13.3258 12.2652 13.0329 11.9723L11.0633 10.0026L13.0329 8.03293C13.3258 7.74004 13.3258 7.26517 13.0329 6.97227C12.74 6.67938 12.2652 6.67938 11.9723 6.97227L10.0026 8.94194L8.03293 6.97227Z"
                          fill="#BABCBF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2295_89482">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              )}
            </>
          }
        </YMapMarker>
        <YMapMarker
          coordinates={[30.486548, 59.915025]}
          onClick={() => {
            typeOfPoints === "choices" && pointsCallback(2);
          }}
        >
          {
            <>
              <div
                className="map_image-container"
                style={{
                  display: isMobile ? 'block' : showTooltip === 2 ? "none" : "block",
                }}
              >
                <img
                  src={selected && selected !== 2 ? MapPointBlack : MapPoint}
                  alt="point"
                  className="map_image"
                  style={{ opacity: selected && selected !== 2 ? "0.7" : "1" }}
                  onClick={() => setShowTooltip(2)}
                />
              </div>
              {showTooltip === 2 && (
                <div className="tooltip-content">
                  <img
                    src={selected && selected !== 2 ? MapPointBlack : MapPoint}
                    alt="point"
                    style={{
                      opacity: selected && selected !== 2 ? "0.7" : "1",
                    }}
                    onClick={() => setShowTooltip(false)}
                  />
                  <p className="title">ТЦ “СЛАВЯНСКИЙ БАЗАР”</p>
                  <p>+7 (812) 317-68-15</p>
                  <p>Товарищеский пр. 20/27, этаж 2, секция 2/9</p>
                  <p>пн-вс: 10:00 - 19:00</p>
                  <button
                    onClick={() =>
                      window.open(
                        "https://yandex.ru/maps/2/saint-petersburg/house/tovarishcheskiy_prospekt_20_27/Z0kYcg9mTEEPQFtjfXVwdHxhYQ==/?ll=30.486548%2C59.915026&z=17",
                        "_blank"
                      )
                    }
                  >
                    ЯНДЕКС КАРТЫ
                  </button>
                  <div className={"tooltip-content_close"} onClick={() => setShowTooltip(false)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2295_89482)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.0026 0.835938C4.93999 0.835938 0.835938 4.93999 0.835938 10.0026C0.835938 15.0652 4.93999 19.1693 10.0026 19.1693C15.0652 19.1693 19.1693 15.0652 19.1693 10.0026C19.1693 4.93999 15.0652 0.835938 10.0026 0.835938ZM8.03293 6.97227C7.74004 6.67938 7.26517 6.67938 6.97227 6.97227C6.67938 7.26517 6.67938 7.74004 6.97227 8.03293L8.94194 10.0026L6.97227 11.9723C6.67938 12.2652 6.67938 12.74 6.97227 13.0329C7.26517 13.3258 7.74004 13.3258 8.03293 13.0329L10.0026 11.0633L11.9723 13.0329C12.2652 13.3258 12.74 13.3258 13.0329 13.0329C13.3258 12.74 13.3258 12.2652 13.0329 11.9723L11.0633 10.0026L13.0329 8.03293C13.3258 7.74004 13.3258 7.26517 13.0329 6.97227C12.74 6.67938 12.2652 6.67938 11.9723 6.97227L10.0026 8.94194L8.03293 6.97227Z"
                          fill="#BABCBF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2295_89482">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              )}
            </>
          }
        </YMapMarker>
        <YMapMarker coordinates={[30.473639, 59.969286]}>
          {
            <>
              <div
                className="map_image-container"
                style={{
                  display: isMobile ? 'block' : showTooltip === 3 ? "none" : "block",
                }}
              >
                <img
                  src={MapPointBlack}
                  alt="point"
                  className="map_image"
                  onClick={() => setShowTooltip(3)}
                />
              </div>
              {showTooltip === 3 && (
                <div className="tooltip-content">
                  <img
                    src={selected && selected !== 3 ? MapPointBlack : MapPointBlack}
                    alt="point"
                    style={{
                      opacity: selected && selected !== 3 ? "0.7" : "1",
                    }}
                    onClick={() => setShowTooltip(false)}
                  />
                  <p className="title">АВТОСЕРВИС</p>
                  <p>+7 (812) 611-30-15</p>
                  <p> Химиков, 28 лит Е</p>
                  <p>пн-вс: 10.00 - 20.00</p>
                  <button
                    onClick={() =>
                      window.open("https://yandex.ru/maps/-/CHUbaQ1O", "_blank")
                    }
                  >
                    ЯНДЕКС КАРТЫ
                  </button>
                  <div className={"tooltip-content_close"} onClick={() => setShowTooltip(false)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2295_89482)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.0026 0.835938C4.93999 0.835938 0.835938 4.93999 0.835938 10.0026C0.835938 15.0652 4.93999 19.1693 10.0026 19.1693C15.0652 19.1693 19.1693 15.0652 19.1693 10.0026C19.1693 4.93999 15.0652 0.835938 10.0026 0.835938ZM8.03293 6.97227C7.74004 6.67938 7.26517 6.67938 6.97227 6.97227C6.67938 7.26517 6.67938 7.74004 6.97227 8.03293L8.94194 10.0026L6.97227 11.9723C6.67938 12.2652 6.67938 12.74 6.97227 13.0329C7.26517 13.3258 7.74004 13.3258 8.03293 13.0329L10.0026 11.0633L11.9723 13.0329C12.2652 13.3258 12.74 13.3258 13.0329 13.0329C13.3258 12.74 13.3258 12.2652 13.0329 11.9723L11.0633 10.0026L13.0329 8.03293C13.3258 7.74004 13.3258 7.26517 13.0329 6.97227C12.74 6.67938 12.2652 6.67938 11.9723 6.97227L10.0026 8.94194L8.03293 6.97227Z"
                          fill="#BABCBF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2295_89482">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              )}
            </>
          }
        </YMapMarker>
        {newPoint && (
          <YMapMarker coordinates={newPoint.coordinates}>
            <div className="map_image-container">
              <OverlayTrigger overlay={<Tooltip>{newPoint.tooltip}</Tooltip>}>
                <img
                  src={selected && selected !== 3 ? MapPointBlack : MapPoint}
                  alt="point"
                  className="map_image"
                  style={{ opacity: selected && selected !== 3 ? "0.7" : "1" }}
                  onClick={() => {
                    typeOfPoints === "choices"
                      ? pointsCallback(3)
                      : console.log("");
                  }}
                />
              </OverlayTrigger>
            </div>
          </YMapMarker>
        )}
        <YMapDefaultSchemeLayer customization={customizationArray} />
      </YMap>
    );
  } catch (e) {
    return (
      <iframe
        className={"map_container"}
        title={"Map"}
        src={process.env.REACT_APP_YMAP_LINK}
        frameBorder="0"
      ></iframe>
    );
  }
};
