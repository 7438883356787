import React, {FC, useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";

import styles from "./AppArrow.module.scss";
import classNames from "classnames";

export type AppArrowProps = {
    last?: boolean;
    color: "red" | "gray";
}

const AppArrow:FC<AppArrowProps> = ({ last, color = "red" }) => {
    const isLargeScreen = useMediaQuery({query: '(min-width: 1599px)'});
    const [margin, setMargin] = useState("");

    useEffect(() => {
        const container = document.querySelector(".container-xxl") as Element;
        const marginRight = window.getComputedStyle(container).marginRight;

        setMargin(marginRight)
    }, [isLargeScreen]);

    const appArrowCN = classNames({
        [styles.AppArrow]: true,
        [styles.ArrowGray]: color === "gray",
        [styles.ArrowRed]: color === "red",
    })


    return <div
        className={appArrowCN}
        style={{width: last && isLargeScreen ? `calc(100% + 255px)` : "100%"}}
    >
        <div className={styles.AppArrowEnd}>
        </div>
    </div>
}

export default AppArrow;