import { CarDataType } from "../../../../../types/RentTypes";
import { CarBookingStepsType } from "../../../CarRentForm";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ModalTemplateInput } from "../../../ModalFormTemplate";
import { Map } from "../../../YandexMap/YandexMap";
import AddressSelect from "../../../AddressSelect/AddressSelect";

export const RentModalMobileAddress = ({
  step,
  setStep,
  car,
  prevStep,
}: {
  step: CarBookingStepsType;
  setStep: (e: CarBookingStepsType) => void;
  car: CarDataType;
  prevStep: () => void;
}) => {
  const send = () => {
    setStep('payment');
  };
  const [newPoint, setNewPoint] = useState<{
    coordinates: [number, number];
    tooltip: string;
  }>();

  const [passed, setPassed] = useState(false);
  const [debounceTimerId, setDebounceTimerId] = useState<NodeJS.Timeout>();
  const [selectedAddress, setSelectedAddress] = useState<number>(1);
  const addressOptions = [
    {
      id: 1,
      name: "СПб, Торфяная дорога, 7Ф, БЦ «Гулливер» 2 оф. 104",
      title: 'БЦ «Гулливер» 2',
      text: 'Торфяная дорога, 7Ф, оф. 104'
    },
    {
      id: 2,
      name: "Товарищеский проспект, 20/27",
      title: 'Славянский Баразар',
      text: 'Товарищеский проспект, 20/27'
    },
    {
      id: 3,
      name: "Доставка автомобиля до адреса",
    },
  ];

  const update = (index: number, value: string) => {};

  const [deliveryAddress, setDeliveryAddress] = useState<string>("");

  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);

  const { data, error, refetch } = useQuery({
    queryKey: ["delivery coordinates"],
    enabled: false,
    queryFn: () =>
      axios.get(
        `https://geocode-maps.yandex.ru/1.x/?apikey=5af93faa-7ddc-4d42-ad93-d93f6b01fd5a&geocode=${deliveryAddress.replaceAll(
          /\s/g,
          "+"
        )}&format=json`
      ),
  });

  useEffect(() => {
    data &&
      data.data.response?.GeoObjectCollection?.metaDataProperty
        .GeocoderResponseMetaData.found !== 0 &&
      setDeliveryAddress(
        data.data.response.GeoObjectCollection.featureMember[0].GeoObject.name
      );
      data &&
      setNewPoint({
        coordinates:
          data.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(
            " "
          ),
        tooltip:
          data.data.response.GeoObjectCollection.featureMember[0].GeoObject
            .name,
      });
  }, [data]);

  useEffect(() => {
    // Отменяем предыдущий таймер при каждом изменении состояния поиска
    clearTimeout(debounceTimerId);

    // Устанавливаем новый таймер для задержки перед выполнением refetch
    const timerId = setTimeout(() => {
      deliveryAddress.trim().length > 0 && refetch();
    }, 2000); // Задержка

    // Сохраняем идентификатор таймера в состоянии
    setDebounceTimerId(timerId);

    // Возвращаем функцию очистки для useEffect, которая будет вызвана при размонтировании компонента или изменении зависимости
    return () => clearTimeout(timerId);
  }, [deliveryAddress]);

  return (
    <>
      <p>Укажите адрес откуда будете забирать автомобиль</p>
      <div className="form-datepicker_container_mobile">
        <div className={"d-flex flex-column gap-2 mt-px-10"}>
          <AddressSelect
            options={addressOptions}
            placeholder="Адрес СТО"
            selected={selectedAddress}
            onSelect={setSelectedAddress}
          />
          {selectedAddress === 3 && (
            <ModalTemplateInput
              small={false}
              placeholder="Введите адрес"
              onChange={(e) => setDeliveryAddress(e.target.value)}
              value={deliveryAddress}
            />
          )}
          <div
            style={{
              width: "100vw",
              height: "400px",
              position: "relative",
              transform: "translateX(-15px)",
            }}
          >
            {selectedAddress === 0 && (
              <div className="map-label-mobile">
                <div className="map-label-mobile_title">АДРЕС</div>
              </div>
            )}
            <Map
              zoom={20}
              selected={selectedAddress}
              typeOfPoints="choices"
              pointsCallback={setSelectedAddress}
              setDeliveryAddress={setDeliveryAddress}
              newPoint={newPoint}
              setNewPoint={setNewPoint}
            />
          </div>
        </div>
        <div className="d-flex justify-content-around mt-px-20 gap-3">
          <button
            className={"site-btn big dark mb-px-25 dark"}
            onClick={() => prevStep()}
          >
            Назад
          </button>
          <button
            className={"site-btn big dark mb-px-25 dark"}
            onClick={() => send()}
          >
            Далее
          </button>
        </div>
      </div>
    </>
  );
};
