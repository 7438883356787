import React, { useEffect, useState } from "react";
import RentItemLayout from "../../../layout/RentItemLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import RentService from "../../../../api-functions/rent-page/rent-service";
import styles from "./RentItemPage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import CustomCarousel from "../../../common/CustomCarousel/CustomCarousel";
import CustomBackButton from "../../../common/CustomBackButton/CustomBackButton";
import CarRentTags from "../../../common/CarTag/CarRentTags";
import { RentBookingPaymentStatus } from "../../../../types/RentTypes";
import { useAuth } from "../../../../hooks/useAuth";
import { CarBookingStepsType } from "../../../common/CarRentForm";
import api from "../../../../core/axios";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../../Api";
import { BrowserView, MobileView } from "react-device-detect";
import RentCarDetailModal from "../../../common/RentCarDetailModal";
import { RentModalMobile } from "../../../common/Rent/RentModalMobile/RentModalMobile";
import Utils from "../../../../utils/Utils";
import Loader from "../../../common/Loader";
import { MetaTags } from "../../../layout/BaseLayout";
import MetaDecorator from "../../../layout/MetaDecorator";
import { Container } from "react-bootstrap";
import { TabsPanel } from "../../../common/TabsPanel/TabsPanel";
import CarInfo from "../../Car/CarInfo";
import { ICarData } from "../../../../types/CatalogTypes";
import { CarData } from "../../../common/CardTypes";
import { format } from "date-fns";

const RentItemPage = () => {
  // Локация на сайте для получения id автомобииля
  const location = useLocation();
  // Функция для перемещения по страницам
  const navigate = useNavigate();
  // Полное имя автомобиля
  const [carFullName, setCarFullName] = useState<null | string>(null);
  // Статус оплаты, проверяется после шага с оплатой
  const [paymentStatus, setPaymentStatus] =
    useState<RentBookingPaymentStatus>(null);
  const { isAuthenticated, has_profile, user_status } = useAuth();
  const [step, setStep] = useState<CarBookingStepsType>("start");
  const [depositPrice, setDepositPrice] = useState(0);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [dates, setDates] = useState<{
    StartDate: string;
    EndDate: string | null | undefined;
  }>({
    StartDate: format(new Date(), "yyyy-MM-dd"),
    EndDate: null,
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const { carID, id: pageId } = useParams();
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: [`rent-car-${carID}`],
    queryFn: async () => await RentService.getOneCarPromise(carID),
  });

  const onOpenModalClick = () => {
    // if (isAuthenticated && has_profile) {
    //   (async () => {
    //     await getPriceCar();
    //   })();
    // } else
    console.log(isAuthenticated);
    if (!has_profile && isAuthenticated === false) {
      setStep("start");
    } else if (isAuthenticated && !has_profile) {
      setStep("create");
    } else {
      setStep("choose_date");
    }
    setIsModalOpen(true);
  };

  useEffect(() => {
    checkCardPayment();
  }, [location]);

  const backFunc = (isOpen: boolean) => {
    if (isOpen) {
      setIsModalOpen(false);
    } else {
      navigate(`/rent/page/${pageId}`);
    }
  };

  const checkCardPayment = () => {
    if (!location.state) {
      return;
    }

    if (location.state.status === "success") {
      setPaymentStatus(location.state.payment_status);
      setCarFullName(location.state.car_name);
      setStep("booking_result");
      setIsModalOpen(true);
    } else {
      setPaymentStatus("CANCELLED");
      setStep("booking_result");
    }
  };

  const getPriceCar = async () => {
    setIsModalLoading(true);
    try {
      const res = await api.get(
        `/voshod-auto/?w=book-a-car&id=${carID}&start=${dates?.StartDate}${
          dates?.EndDate !== undefined ? `&end=${dates?.EndDate}` : ""
        }&type=taxi`,
        {
          withCredentials: true,
        }
      );
      if (res.data.result === 1) {
        setDepositPrice(res.data.summ);
        if (res.data.summ > 0) setStep("payment");
        else setStep("booking_success");
      }
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<ErrorResponse>).response?.data.message ??
          "Возникла ошибка с сервером поробуйте позже"
      );
    } finally {
      setIsModalLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const car = data?.data.item;

  const title = `${car?.brand} ${car?.model} - ${process.env.REACT_APP_WEBSITE_NAME}`;
  const meta: MetaTags = {
    description: car?.brand + " " + car?.model + " в лизинг или аренду",
    keywords: `аренда, лизинг,${car?.brand} ${car?.model}, ${car?.brand}, ${car?.model}`,
  };
  const finalPrice = (): number => {
    if (!car || !car.rentpay) return 0;

    const rentPayValues = Object.values(car.rentpay) as (number | null)[];
    const lastValue = rentPayValues[rentPayValues.length - 1];
    const secondLastValue = rentPayValues[rentPayValues.length - 2];

    if (lastValue !== null) {
      return lastValue;
    }
    if (rentPayValues.length > 1 && secondLastValue !== null) {
      return secondLastValue;
    }

    return 0;
  };
  return (
    <>
      <MetaDecorator
        title={title}
        url={`/rent/page/${pageId}/car/${carID}`}
        image={car?.images[0].image}
      />
      <RentItemLayout
        backFunc={() => backFunc(isModalOpen)}
        headerSelectedLink={"/rent/page/1"}
        title={title}
        meta={meta}
      >
        <Container className={styles.containerXxl} fluid={"xxl"}>
          <div className={styles.rentItemPage}>
            <div className={styles.rentItemCarGallery}>
              <CustomBackButton
                className={styles.backButton}
                path={"/rent/page/1"}
              >
                <FontAwesomeIcon icon={faAngleLeft} size={"sm"} />
                &nbsp;&nbsp;Вернуться в каталог
              </CustomBackButton>
              {car && <CustomCarousel images={car.images} />}
              <BrowserView>
                {car?.info && (
                  <CarInfo
                    car_data={car as ICarData}
                    type={"descktop"}
                    car={car}
                  />
                )}
              </BrowserView>
            </div>
            {car && (
              <div className={styles.rentItemCarInfo}>
                <h2 className={styles.rentItemCarBrand}>
                  {car.brand}
                  <span className={styles.rentItemCarModel}> {car.model}</span>
                </h2>
                <div className={styles.rentItemCarRegnum}>{car.regnum}</div>
                <div className={styles.rentItemCarTagsWrapper}>
                  <CarRentTags
                    tagStyle={styles.rentItemCarTagsWrapperTagStyles}
                    car={car}
                  />
                </div>
                <div className={car.discount ? styles.rentItemCarDiscountRentPay : styles.rentItemCarRentPay}>
                  {car.rentpay && <div>Платёж от:</div>}
                  {car.rentpay && (
                    <div>
                      <span>
                        {car.discount ? Utils.numberWithSpaces(
                          (finalPrice() * (100 - car.discount)) / 100
                        ) : finalPrice()}
                      </span>{" "}
                      / сут
                      {car.discount && <p>
                        <del>{finalPrice()} ₽</del> {`-${car.discount}%`}
                      </p>}
                    </div>
                  )}
                  <div>{car.rent_description}</div>

                  <div>
                    Депозит от <span>{car.deposit} ₽</span>
                  </div>
                </div>

                <div className={styles.rentItemCarButtonWrapper}>
                  <button onClick={onOpenModalClick} className={"site-btn big"}>
                    Забронировать
                  </button>
                </div>

                <div className={styles.rentItemCarInfoWrapper}>
                  {car.info && <h3>Информация</h3>}
                  {car.info &&
                    car.info.list.map(
                      (info: { name: string; value: string; id: number }) => {
                        return (
                          <div key={info.name}>
                            <span>{info.name}</span>
                            <span>{info.value}</span>
                          </div>
                        );
                      }
                    )}
                </div>
                <div className={styles.rentItemCarTarrifsWrapper}>
                  <h3>Тариф</h3>
                  {car.rentpay &&
                    (() => {
                      const rentpayKeys = Object.keys(car.rentpay);

                      return rentpayKeys.map((item, index) => {
                        const currentValue = car.rentpay[item]; // Текущее значение
                        const previousValue =
                          index > 0
                            ? car.rentpay[rentpayKeys[index - 1]]
                            : null; // Предыдущее значение

                        // Проверяем, что текущая и предыдущая записи являются числами
                        const safeValue =
                          typeof currentValue === "number"
                            ? currentValue
                            : typeof previousValue === "number"
                            ? previousValue
                            : null;

                        const formattedValue =
                          safeValue !== null
                            ? Utils.numberWithSpaces(safeValue)
                            : "—";

                        const isLastItem = index === rentpayKeys.length - 1;

                        return (
                          <div
                            key={item}
                            className="d-flex justify-content-between"
                          >
                            <span>
                              {isLastItem ? `от ${item} д.` : `до ${item} д.`}
                            </span>
                            <span>
                              {formattedValue} ₽{" "}
                              <span style={{ color: "#606569" }}> / сут</span>
                            </span>
                          </div>
                        );
                      });
                    })()}
                </div>
                <MobileView>
                  {car?.info && (
                    <CarInfo
                      car_data={car as ICarData}
                      type={"mobile"}
                      car={car}
                    />
                  )}
                </MobileView>
                {isModalOpen && (
                  <>
                    <BrowserView>
                      {isSuccess && car ? (
                        <>
                          <RentCarDetailModal
                            isModalLoading={isModalLoading}
                            closeModal={setIsModalOpen}
                            errorMessage={errorMessage}
                            step={step}
                            setStep={setStep}
                            paymentStatus={paymentStatus}
                            setPaymentStatus={setPaymentStatus}
                            getPriceCar={getPriceCar}
                            depositPrice={depositPrice}
                            setDepositPrice={setDepositPrice}
                            setCarName={setCarFullName}
                            carName={carFullName}
                            setDates={setDates}
                            dates={dates}
                            type={"taxi"}
                          />
                        </>
                      ) : (
                        <>
                          <h3>Машина не найдина, повторите попытку позжe</h3>
                        </>
                      )}
                    </BrowserView>
                    <MobileView>
                      {isSuccess && car ? (
                        <>
                          <RentModalMobile
                            step={step}
                            setStep={setStep}
                            active={isModalOpen}
                            setActive={setIsModalOpen}
                            car={car}
                            depositPrice={depositPrice}
                            getPriceCar={getPriceCar}
                            setDepositPrice={setDepositPrice}
                            paymentStatus={paymentStatus}
                            setPaymentStatus={setPaymentStatus}
                            carName={carFullName}
                            setCarName={setCarFullName}
                            setDates={setDates}
                            dates={dates}
                            type={"taxi"}
                          />
                        </>
                      ) : (
                        <>
                          <h3>Машина не найдина, повторите попытку позжe</h3>
                        </>
                      )}
                    </MobileView>
                  </>
                )}
              </div>
            )}
          </div>
        </Container>
      </RentItemLayout>
    </>
  );
};

export default RentItemPage;
