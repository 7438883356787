import { createStaticRanges, DateRangePicker } from "react-date-range";
import { CarDataType } from "../../../../../types/RentTypes";
import { CarBookingStepsType } from "../../../CarRentForm";
import { useEffect, useRef, useState } from "react";
import { rangeProps } from "../../../../pages/Fines/FinesPage";
import api from "../../../../../core/axios";
import { ru } from "date-fns/locale";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { addDays, format, isBefore, isSameDay, parseISO } from "date-fns";

export const RentModalMobileDate = ({
  step,
  setStep,
  car,
  prevStep,
  propsSetDates,
  getPriceCar,
}: {
  step: CarBookingStepsType;
  setStep: (e: CarBookingStepsType) => void;
  car: CarDataType;
  prevStep: () => void;
  propsSetDates: React.Dispatch<
    React.SetStateAction<{
      StartDate: string;
      EndDate: string | null | undefined;
    }>
  >;
  getPriceCar: () => void;
}) => {
  // ЛОКАЛЬНЫЕ СОСТОЯНИЯ
  const [passed, setPassed] = useState(false);
  const [error, setError] = useState<null | string>(null);
  // Массив недоступных дат
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);
  // Хук для хранения начала открытого интервала
  const openIntervalStart = useRef<Date | null>(null);

  // ЗАВИСИМОСТИ
  useEffect(() => {
    // Если есть недоступные интервалы
    if (car.unavailable_intervals) {
      // Устанавливаем start и end, используя ближайшую доступную дату
      const today = new Date();
      const closestStart = findClosestAvailableDate(
        today,
        getDynamicExcludeDatesWithoutLast({
          unavailableIntervals: car.unavailable_intervals,
        })
      );
      const closestEnd = findClosestAvailableDate(
        addDays(closestStart, 1),
        getDynamicExcludeDatesWithoutLast({
          unavailableIntervals: car.unavailable_intervals,
        })
      );

      // Устанавливаем найденные даты в локальное состояние
      setDates({
        startDate: closestStart,
        endDate: closestEnd,
        key: "selection",
      });

      // Устанавливам недоступные даты без последнего дня у каждого интервала
      // Потому в последний день недоступного интервала авто уже будет свободно
      setDisabledDates(
        getDynamicExcludeDatesWithoutLast({
          unavailableIntervals: car.unavailable_intervals,
        })
      );
    }
  }, [car]);

  // Функция для поиска ближайшей доступной даты
  function findClosestAvailableDate(start: Date, disabledDates: Date[]): Date {
    // Начинаем со start, затем будем увеличивать пока не найдем дату, которой нет в disabledDates
    let currentDate = start;
    // Ищем ближайшую доступную дату
    while (disabledDates.some((date) => isSameDay(date, currentDate))) {
      currentDate = addDays(currentDate, 1); // Перемещаемся на следующий день
    }
    return currentDate;
  }

  // Функция для получения недоступных дат с исключением последнего дня интервала
  const getDynamicExcludeDatesWithoutLast = ({
    unavailableIntervals,
  }: {
    unavailableIntervals: {
      StartDate: string;
      EndDate?: string;
      Type: string;
    }[];
  }): Date[] => {
    // Берем интервалы
    return unavailableIntervals.flatMap((interval) => {
      // Дата начала интервала есть всегда
      const startDate = parseISO(interval.StartDate);
      // Дата конца интервала может быть открытой
      const endDate = interval.EndDate ? parseISO(interval.EndDate) : undefined;
      // Если дата действительно открыта
      if (!endDate) {
        // Предполагается, что открытый интервал может быть только одним, иначе они наложаться друг на друга
        // Сохраняем его начало в ref
        openIntervalStart.current = startDate;
        return []; // Возвращаем пустой массив, чтобы исключить открытый интервал
      } else {
        // Убираем последний день интервала и возвращаем даты
        return getDatesBetween(
          startDate.toISOString(),
          addDays(endDate, -1).toISOString()
        );
      }
    });
  };

  // Ищет наиболее дальнюю свободную дату
  function findFarthestAvailableDate(start: Date, end: Date): Date {
    let farthestDate = start;
    // Итерируемся по всем датам между start и end
    let currentDate = start;
    while (isBefore(currentDate, end)) {
      currentDate = addDays(currentDate, 1);
      // Если текущая дата доступна, обновляем farthestDate
      if (!disabledDates.some((date) => isSameDay(date, currentDate))) {
        farthestDate = currentDate;
      } else {
        // Если наткнулись на недоступную дату, прерываем цикл
        break;
      }
    }
    return farthestDate; // Возвращаем последнюю доступную дату
  }
  // Генерация дат между StartDate и EndDate
  // Принимает интеравал строк, возвращает массив недоступных дат
  const getDatesBetween = (startDate: string, endDate: string): Date[] => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates: Date[] = [];

    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      dates.push(new Date(date));
    }

    return dates;
  };

  const isDisabledOpenRangeRef = useRef<Date | null>(null);

  const getRangefromAPI = async () => {
    const carId = car.id;
    try {
      const res = await api.get(
        `/voshod-auto/?w=get-book-car-interval&car_id=${carId}&type=free`,
        { withCredentials: true }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  // Функция для получения недоступных дат, с исключением первого дня ближайшего интервала
  // Вызывается, если есть начало интервала, но пока нет окончания.
  // В таком случае для сдачи авто доступна та дата, в которую ее забирают в следующем интервале.
  const getDynamicExcludeDates = ({
    unavailableIntervals,
    selectedDate,
  }: {
    unavailableIntervals: {
      StartDate: string;
      EndDate?: string;
      Type: string;
    }[];
    selectedDate: Date;
  }): Date[] => {
    const selected = selectedDate;

    return unavailableIntervals
      .flatMap((interval) => {
        const startDate = parseISO(interval.StartDate);
        const endDate = interval.EndDate
          ? parseISO(interval.EndDate)
          : undefined;

        if (!endDate) {
          // Сохраняем начало открытого интервала
          openIntervalStart.current = startDate;
          return []; // Пропускаем открытые интервалы
        }

        // Если выбранная дата перед началом интервала
        if (isBefore(selected, startDate)) {
          return getDatesBetween(
            addDays(startDate, 1).toISOString(), // Исключаем первый день
            endDate.toISOString()
          );
        }

        // Если выбранная дата после интервала или внутри интервала
        return getDatesBetween(startDate.toISOString(), endDate.toISOString());
      })
      .filter((date) => !isSameDay(date, selected)); // Исключаем выбранную дату
  };

  const busyDates = car.unavailable_intervals
    ? car.unavailable_intervals.map((dates) => {
        return {
          from: format(parseISO(dates.StartDate), "dd.MM.yyyy"),
          to:
            dates.EndDate === null
              ? null
              : format(parseISO(dates.EndDate), "dd.MM.yyyy"),
        };
      })
    : [];

  // Функция принимает возвращает корректную дату принимая строку
  const parseDate = (dateString: string): Date => {
    const [day, month, year] = dateString.split(".");
    if (
      !day ||
      !month ||
      !year ||
      isNaN(+day) ||
      isNaN(+month) ||
      isNaN(+year)
    ) {
      throw new Error(`Неверный формат даты: ${dateString}`);
    }
    const date = new Date(`${month}.${day}.${year}`);
    if (isNaN(date.getTime())) {
      throw new Error(`Невозможно создать дату из строки: ${dateString}`);
    }
    return date;
  };

  const getDatesArray = (busyDates: { from: string; to: string | null }[]) => {
    const allDates: Date[] = [];

    busyDates.forEach(({ from, to }) => {
      const startDate = parseDate(from);

      if (to === null) {
        // Открытый интервал — сохраняем его начало и пропускаем
        openIntervalStart.current = new Date(startDate);
        return;
      }

      const endDate = parseDate(to);
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        allDates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    });

    return allDates;
  };

  useEffect(() => {
    getRangefromAPI().then((res) => {
      console.log(res);
    });
  }, []);

  const isSameDate = (date1: Date, date2: Date) => {
    return (
      date1 &&
      date2 &&
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isDisabled = (day: Date): boolean => {
    const busyDatesArray = disabledDates;

    // Получаем текущую дату без времени
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Блокируем день, если он в прошлом
    if (day.getTime() < today.getTime()) {
      return true;
    }

    // Проверяем, относится ли день к открытому интервалу
    if (
      openIntervalStart.current &&
      day.getTime() >= openIntervalStart.current.getTime()
    ) {
      return true;
    }

    // Проверяем, совпадает ли день с одной из заблокированных дат
    if (busyDatesArray.some((busyDate) => isSameDate(busyDate, day))) {
      return true;
    }

    // Если ни одно условие не сработало, день не заблокирован
    return false;
  };

  const isDisabledRange = (
    startDate: Date | null,
    endDate: Date | null
  ): boolean => {
    if (!startDate || !endDate) return true; // Если диапазон невалидный, он считается недоступным

    let currentDate = new Date(startDate); // Начинаем с начальной даты
    while (currentDate <= endDate) {
      if (isDisabled(currentDate)) {
        return true; // Если хотя бы одна дата недоступна, весь диапазон недоступен
      }
      currentDate.setDate(currentDate.getDate() + 1); // Переходим к следующему дню
    }

    return false; // Все даты в диапазоне доступны
  };

  const send = () => {
    getPriceCar();
    // props.setStep("address");
  };

  const [dates, setDates] = useState<{
    startDate: Date;
    endDate: Date | undefined;
    key: string;
  }>({
    startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    const start = ranges.selection.startDate;
    const end = ranges.selection.endDate;

    // Если выбрана только дата начала
    if (start === end) {
      // Разблокирована ближайшая дата недоступного интервала
      setDisabledDates(
        getDynamicExcludeDates({
          unavailableIntervals: car.unavailable_intervals,
          selectedDate: start,
        })
      );
      setDates({ startDate: start, endDate: undefined, key: "selection" });
      propsSetDates({
        StartDate: format(start, "yyyy-MM-dd"),
        EndDate: undefined,
      });
      return;
    }

    // Если выбраны обе даты
    if (start !== end) {
      // Получаем текущую дату в формате только числа (год, месяц, день)
      const today = new Date();
      const todayOnlyDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );

      // Преобразуем start в формат только числа
      const startOnlyDate = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate()
      );

      // Проверяем, находится ли дата начала в недоступных датах или является датой в прошлом
      if (
        start &&
        (disabledDates.some((date) => isSameDay(date, start)) ||
          isBefore(startOnlyDate, todayOnlyDate))
      ) {
        console.log(
          "Выбранная дата начала находится в недоступных датах или в прошлом. Прерываем."
        );
        return; // Прерываем выполнение функции
      }
      // Проверяем валидность диапазона
      if (
        !isRangeValid({
          start,
          end,
        })
      ) {
        // Если диапазон невалиден, находим ближайшую доступную дату
        const adjustedEnd = findFarthestAvailableDate(start, end);
        // Устанавливаем диапазон с новой датой окончания
        setDates({ startDate: start, endDate: adjustedEnd, key: "selection" });
        propsSetDates({
          StartDate: format(start, "yyyy-MM-dd"),
          EndDate: format(adjustedEnd, "yyyy-MM-dd"),
        });
      } else {
        // Диапазон валиден
        setDisabledDates(
          getDynamicExcludeDatesWithoutLast({
            unavailableIntervals: car.unavailable_intervals,
          }).filter((date) => !isSameDay(date, start) && !isSameDay(date, end))
        );
        setDates({ startDate: start, endDate: end, key: "selection" });
        propsSetDates({
          StartDate: format(start, "yyyy-MM-dd"),
          EndDate: format(end, "yyyy-MM-dd"),
        });
      }
    }
  };

  // Валиден ли выбранный массив, то есть нет ли внутри него недоступных дат
  const isRangeValid = ({
    start,
    end,
  }: {
    start: Date;
    end: Date;
  }): boolean => {
    if (!start || !end) return true;

    // Список всех дат между start и end
    const datesInRange = getDatesBetween(
      start.toISOString(),
      end.toISOString()
    );

    // Проверяем, есть ли пересечения с disabledDates
    return !datesInRange.some((date) =>
      disabledDates.some((excluded) => isSameDay(date, excluded))
    );
  };

  useEffect(() => {
    const startDate = format(dates.startDate, "yyyy-MM-dd");
    const endDate = dates.endDate && format(dates.endDate, "yyyy-MM-dd");

    const datesToSet: {
      StartDate: string;
      EndDate: string | undefined | null;
    } = {
      StartDate: startDate,
      EndDate: undefined,
    };
    if (startDate !== endDate) {
      datesToSet.EndDate = endDate;
    }

    propsSetDates(datesToSet);
  }, []);

  // Функция для проверки, пересекается ли диапазон с недоступными датами
  const isDateRangeOverlapping = (startDate: Date, endDate: Date) => {
    return getDatesArray(busyDates).some((busyDate) => {
      return (
        (busyDate >= startDate && busyDate <= endDate) ||
        (busyDate >= startDate && busyDate <= endDate)
      );
    });
  };

  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "3 дня",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 3)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "Неделю",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "2 недели",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 14)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "Месяц",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 29)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "Другая дата",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(),
        }),
        isDisabled: function () {
          return false;
        },
        isSelected: function () {
          // Если ни один другой диапазон не выбран, возвращаем true для "Другой даты"
          return !customDateObjects
            .slice(0, 4)
            .map((option) => option.isSelected())
            .includes(true);
        },
      },
    ];

    return customDateObjects;
  };

  const SideBar = sideBarOptions();
  const StaticRanges = createStaticRanges(
    SideBar.filter((range) => !range.isDisabled())
  );

  return (
    <>
      <p>Выберите даты бронирования</p>
      <div className="form-datepicker_container_mobile">
        <DateRangePicker
          className="d-flex flex-column-reverse gap-3"
          locale={ru}
          onChange={handleSelect}
          ranges={[dates]}
          weekdayDisplayFormat={"EEEEEE"}
          shownDate={new Date()}
          disabledDay={(day) => isDisabled(day)}
          minDate={new Date()}
          staticRanges={StaticRanges}
          showMonthAndYearPickers={false}
          dayContentRenderer={(day) =>
            isDisabled(day) ? (
              <OverlayTrigger
                rootClose={true}
                trigger="click"
                placement="top"
                overlay={
                  <Tooltip>Невозможно забронировать выбранную дату</Tooltip>
                }
              >
                <span>{day.getDate()}</span>
              </OverlayTrigger>
            ) : (
              <span>{day.getDate()}</span>
            )
          }
        />
        <div className="return_atention">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM13.0083 7.09284C13.0083 6.53729 12.5579 6.08692 12.0024 6.08692C11.4468 6.08692 10.9965 6.53729 10.9965 7.09284V12.0019C10.9965 12.5575 11.4468 13.0078 12.0024 13.0078C12.5579 13.0078 13.0083 12.5575 13.0083 12.0019V7.09284ZM13.0083 16.9108C13.0083 16.3552 12.5579 15.9048 12.0024 15.9048C11.4468 15.9048 10.9965 16.3552 10.9965 16.9108V17.4562C10.9965 18.0118 11.4468 18.4621 12.0024 18.4621C12.5579 18.4621 13.0083 18.0118 13.0083 17.4562V16.9108Z"
              fill="#222222"
            />
          </svg>

          {`Автомобиль необходимо сдать ${
            dates.endDate !== undefined
              ? format(dates.endDate, "dd.MM.yyyy")
              : ""
          } с 9.00 до 11.00`}
        </div>
        <div className="d-flex justify-content-around mt-px-20 gap-3">
          <button
            className={"site-btn big dark mb-px-25 dark"}
            onClick={() => prevStep()}
          >
            Назад
          </button>
          <button
            className={"site-btn big dark mb-px-25 dark"}
            onClick={() => send()}
          >
            Далее
          </button>
        </div>
      </div>
    </>
  );
};
