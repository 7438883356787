import { useEffect, useRef, useState } from "react";
import "./AddressSelect.scss";

interface optionProps {
  name: string;
  id: number;
}

export const SelectOption: React.FC<{
  value: optionProps;
  onSelect: React.Dispatch<any>;
}> = (props) => {
  const { value, onSelect } = props;
  return (
    <li
      className="address-select_option"
      onClick={() => onSelect({ name: value.name, id: value.id })}
    >
      <div className="address-select_name">{value.name}</div>
    </li>
  );
};

const AddressSelect: React.FC<{
  options: optionProps[];
  placeholder: string;
  selected: number;
  onSelect: React.Dispatch<any>;
}> = (props) => {
  const { options, placeholder, selected: externalSelected, onSelect } = props;

  const [selected, setSelected] = useState<{
    name: string;
    id: number;
  } | null>(null);

  const [active, setActive] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Находим соответствующий вариант по переданному извне selected id
    const selectedOption = options.find(option => option.id === externalSelected);
    if (selectedOption) {
      setSelected(selectedOption);
    }
  }, [externalSelected, options]);

  const setOption = (value: { name: string; id: number }) => {
    setSelected({
      name: value.name,
      id: value.id,
    });
    onSelect(value.id);
    setActive(false);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (e.target instanceof Node && !selectRef.current?.contains(e.target))
        setActive(false);
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="address-select" ref={selectRef}>
      <div
        className={"address-select_head "}
        onClick={() => setActive((prev) => !prev)}
      >
        <span
          className={"address-select_value " + (selected ? "selected" : "")}
        >
          {
            <>
              {selected ? (
                <>
                  <span>{selected.name}</span>
                </>
              ) : (
                <>{placeholder}</>
              )}
            </>
          }
        </span>
        <div className={"address-select_icon " + (active ? "active" : "")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M5.5 8.25L11 13.75L16.5 8.25"
              stroke="#BABCBF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {active && (
        <div className="address-select_dropdown">
          <ul className="address-select_list">
            {props.options.map((item, index) => (
              <SelectOption key={index} value={item} onSelect={setOption} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AddressSelect;
