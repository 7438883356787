import React, { FC } from "react";
import { CarDataType } from "../../../../../types/RentTypes";

const RentModalMobilePaymentSuccess: FC<{
  closeFunc: () => void;
  car: CarDataType;
}> = (props) => {
  return (
    <div className="pt-px-20">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 60 60"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.667969 29.9998C0.667969 13.7995 13.801 0.666504 30.0013 0.666504C46.2017 0.666504 59.3346 13.7995 59.3346 29.9998C59.3346 46.2002 46.2017 59.3332 30.0013 59.3332C13.801 59.3332 0.667969 46.2002 0.667969 29.9998Z"
            fill="#008F4B"
          />
          <path
            d="M20.2617 30.6319L26.9574 36.956L39.74 23.043"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className={"call-content-text-header mt-px-30"}>
          Оплата <br />
          прошла успешно!
        </div>
        <div className={"call-content-text"}>
          {props.car.brand} {props.car.model} — забронирован!
        </div>
      </div>
      <div>
        <button className={"site-btn small mt-px-70"} onClick={props.closeFunc}>
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default RentModalMobilePaymentSuccess;
