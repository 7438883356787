import { useState } from "react";
import BaseLayout, { MetaTags } from "../../layout/BaseLayout";
import MetaDecorator from "../../layout/MetaDecorator";
import { MainStoCard } from "./MainStoCard/MainStoCard";
import { GuaranteeCard } from "./GuaranteeCard/GuaranteeCard";

const StoPage = () => {
  const title = process.env.REACT_APP_WEBSITE_NAME;
  const meta: MetaTags = {
    description: process.env.REACT_APP_WEBSITE_NAME,
    keywords: "leasing,rent,аренда,авто,автомобиль,лизинг,бронирование",
  };
  const [whiteMenu, setWhiteMenu] = useState(false);
  return (
    <>
      <MetaDecorator title={title ?? "Компания Восход"} />
      <BaseLayout
        whiteMenu={whiteMenu}
        title={title}
        meta={meta}
        headerType={"transparent"}
        headerSelectedLink={"/service"}
        noTopPadding={true}
      >
        <MainStoCard />
        <GuaranteeCard />
      </BaseLayout>
    </>
  );
};

export default StoPage;
