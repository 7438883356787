import React from "react";
import { Container } from "react-bootstrap";
import BaseLayout, { BaseLayoutProps } from "./BaseLayout";
import { useLocation, useNavigate } from "react-router-dom";
import DocPage from "../common/DocPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

type DocumentLayoutProps = {
  documentTitle: string;
  children: any;
};
const DocumentLayout: React.FunctionComponent<
  BaseLayoutProps & DocumentLayoutProps
> = (props: BaseLayoutProps & DocumentLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const back = () => {
    if (location.key !== "default") navigate(-1);
    else navigate("/");
  };
  return (
    <BaseLayout {...props} headerType={"logo"} noFooter noTopPadding noSend>
      <button className="back_btn" onClick={back}>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.125 8.5L6.0813 4.25M2.125 8.5L6.0813 12.75M2.125 8.5H14.875"
            stroke="#222222"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        &nbsp;&nbsp;ВЕРНУТЬСЯ
      </button>
      <Container fluid={"lg"} style={{ maxWidth: "880px" }}>
        {isMobile && <div style={{height:'45px', width: '10px'}}></div>}
        <DocPage header={props.documentTitle}>{props.children}</DocPage>
      </Container>
    </BaseLayout>
  );
};

export default DocumentLayout;
