import React from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import styles from "./CustomBackButton.module.scss";


type BackButton = {
    children: React.ReactNode,
    path?: string,
    className?: string,
}

const CustomBackButton: React.FC<BackButton> =
    ({
         children,
         path,
         className,
    }) => {

    const navigate = useNavigate();

    const backFunction = path ? () => navigate(path) : () => navigate(-1)

    const btnStyles = classNames({
        [styles.backButton]: true,
        [className ? className : ""]: true
    })

    return (
        <button
            onClick={backFunction}
            className={btnStyles}
        >
            {children}
        </button>
    )
}

export default CustomBackButton;
