import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { AxiosError } from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Carousel, FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import Api, { CallRequestData, ConfirmPhone, ErrorResponse } from "../../Api";
import { useAuth } from "../../hooks/useAuth";
import caretLeft from "../../images/common/caret-left-big.svg";
import caretRight from "../../images/common/caret-right-big.svg";
import radioEmpty from "../../images/index/radio-empty.svg";
import mapFrameIcon from "../../images/index/map-frame-icon.svg";
import { ConfirmPaymentQR } from "../../types/AuthContextTypes";
import {
  CarDataType,
  RentBookingPaymentStatus,
  RentCreateAccountForm,
} from "../../types/RentTypes";
import Utils from "../../utils/Utils";
import { CarImagesModal } from "../pages/Car/CarImages";
import bankCardImg from "../../images/common/bank-card.png";
import sbpImg from "../../images/common/sbp.png";
import FileInput from "./FileInput";
import LoadError from "./LoadError";
import Loader from "./Loader";
import {
  ModalTemplateConfirm,
  ModalTemplateContent,
  ModalTemplateInput,
  ModalTemplatePhone,
} from "./ModalFormTemplate";
import api from "../../core/axios";
import { addDays, format, isBefore, isSameDay, parseISO } from "date-fns";
import { ru } from "date-fns/locale";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import { rangeProps } from "../pages/Fines/FinesPage";
import { Map } from "./YandexMap/YandexMap";
import { useQuery } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";

export type CarBookingStepsType =
  | "start"
  | "confirm"
  | "create"
  | "choose_date"
  | "address"
  | "payment"
  | "confirm_payment"
  | "booking_result"
  | "booking_success"
  | "finish";

export const CarRentContacts: React.FC<{
  closeFunc: () => void;
  setStep: (string) => void;
  car: CarDataType;
  setData: (CallRequestData) => void;
  data: ConfirmPhone;
  closeOnBack?: boolean;
  submit: () => void;
  error: string | null;
}> = (props) => {
  const [passed, setPassed] = useState(false);
  const { user_status } = useAuth();

  const send = () => {
    let errors = Utils.validateConfirmPhone(props.data);

    if (Object.keys(errors).length > 0) {
      props.setData({ ...props.data, errors: errors });
      setPassed(false);
      return;
    }
    props.submit();
  };
  const update = (field: string, value: any) => {
    let errors = props.data.errors;
    delete errors[field];
    let newData = { ...props.data, [field]: value, errors: errors };
    props.setData(newData);
    errors = Utils.validateConfirmPhone(newData);
    setPassed(Object.keys(errors).length === 0);
    Utils.validatePhone(props.data.phone);
  };
  return (
    <ModalTemplateContent>
      <div>
        <div className={"mb-px-90"}>
          <button
            className={
              "default-link font-size-18 font-weight-semibold text-hover-default"
            }
            onClick={props.closeFunc}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp;&nbsp;ВЕРНУТЬСЯ
          </button>
        </div>
        <div>
          <div
            className={
              "call-content-text-header font-size-40 line-height-120 mb-px-10"
            }
          >
            Бронирование
            <br />
            {props.car.brand} {props.car.model}
          </div>
          <div className={"call-content-text font-size-16"}>
            Оставьте свой номер телефона,
            <br />
            для регистрации и оплаты бронирования
          </div>
        </div>
      </div>
      <div>
        <div>
          <ModalTemplatePhone
            error={props.data.errors["phone"]}
            onInput={(e: any) => update("phone", e.target.value)}
            value={props.data.phone}
            small={false}
            onChange={(e: any) => update("phone", e.target.value)}
          />
        </div>
        {props.data.errors["server"] && (
          <div className={"my-2 text-red-color font-size-12"}>
            {props.data.errors["server"]}
          </div>
        )}
      </div>
      {props.error && (
        <p className="text-red-color my-px-10 font-fize-14">{props.error}</p>
      )}
      {user_status === "banned" ? (
        <p className="text-red-color my-px-10 font-fize-14">
          Вы забанены, и не можете дальше продвигаться
        </p>
      ) : (
        <div>
          <button
            className={"site-btn small " + (!passed ? "dark" : "")}
            onClick={send}
          >
            Отправить код
          </button>
          <ModalTemplateConfirm
            small={false}
            error={props.data.errors["confirm"]}
            confirmed={props.data.confirm}
            onChange={(e) => update("confirm", e.target.checked)}
          />
        </div>
      )}
    </ModalTemplateContent>
  );
};

export const CarRentConfirmPhone: React.FC<{
  closeFunc: () => void;
  setStep: (string) => void;
  car: CarDataType;
  data: ConfirmPhone;
  timer: number;
  repeatRequest: () => void;
  getPriceCar: () => void;
}> = (props) => {
  const [passed, setPassed] = useState(false);
  const [code, setCode] = useState("      ");
  const [error, setError] = useState("");
  const [idPrefix] = useState(Utils.randomString());
  const { register, error_message } = useAuth();
  const [timer, setTimer] = useState(props.timer);

  useEffect(() => {
    let id = "confirm" + props.car.id + idPrefix + "-0";
    let item = document.getElementById(id) as HTMLInputElement;
    item?.focus();
    item?.setSelectionRange(0, 1);
    setTimer(props.timer);
  }, []);
  useEffect(() => {
    if (timer > 0)
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);
  const timerToString = () => {
    let minutes = ("0" + Math.floor(timer / 60)).slice(-2);
    let seconds = ("0" + (timer % 60)).slice(-2);
    return minutes + ":" + seconds;
  };

  const send = async () => {
    if (code.replace(/\D+/g, "").length < 5) {
      setPassed(false);
      setError("Укажите код подтверждения!");
      return;
    }
    setError("");

    try {
      const res: any = await register(props.data.phone, code);
      if (res.success) {
        if (res.has_profile) {
          await props.setStep("choose_date");
        } else {
          props.setStep("create");
        }
        setPassed(true);
      }
    } catch (error) {
      console.log(error);
      setPassed(false);
    }
  };

  const update = (index: number, value: string) => {
    if (!value.replace(/\D/, "")) {
      setPassed(false);
      return;
    }
    let output = code.substring(0, index) + value + code.substring(index + 1);
    setCode(output);

    let id = "confirm" + props.car.id + idPrefix + "-" + (index + 1);
    if (index < 5) {
      let item = document.getElementById(id) as HTMLInputElement;
      item?.focus();
      item?.setSelectionRange(0, 1);
    }

    let passed = output.replace(/\D+/g, "").length >= 5;
    setPassed(passed);
  };

  useEffect(() => {
    if (code.replace(/\D+/g, "").length === 5) {
      send();
    }
  }, [code]);

  return (
    <ModalTemplateContent>
      <div>
        <div className={"mb-px-90"}>
          <button
            className={
              "default-link font-size-18 font-weight-semibold text-hover-default"
            }
            onClick={() => props.setStep("start")}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp;&nbsp;ВЕРНУТЬСЯ
          </button>
        </div>
        <div>
          <div
            className={
              "call-content-text-header font-size-40 line-height-120 mb-px-10"
            }
          >
            Бронирование
            <br />
            {props.car.brand} {props.car.model}
          </div>
          <div className={"call-content-text font-size-16"}>
            <span className={"text-default"}>Мы отправили вам код</span>
            <br />
            на номер {props.data.phone}
          </div>
        </div>
      </div>
      <div>
        <div className={"d-flex justify-content-between"}>
          <ModalTemplateInput
            id={"confirm" + props.car.id + idPrefix + "-0"}
            container_style={{ maxWidth: "40px" }}
            maxLength={1}
            small={false}
            onInput={(e: any) => update(0, e.target.value)}
          />
          <ModalTemplateInput
            id={"confirm" + props.car.id + idPrefix + "-1"}
            container_style={{ maxWidth: "40px" }}
            maxLength={1}
            small={false}
            onInput={(e: any) => update(1, e.target.value)}
          />
          <ModalTemplateInput
            small={false}
            id={"confirm" + props.car.id + idPrefix + "-2"}
            container_style={{ maxWidth: "40px" }}
            maxLength={1}
            onInput={(e: any) => update(2, e.target.value)}
          />
          <ModalTemplateInput
            small={false}
            id={"confirm" + props.car.id + idPrefix + "-3"}
            container_style={{ maxWidth: "40px" }}
            maxLength={1}
            onInput={(e: any) => update(3, e.target.value)}
          />
          <ModalTemplateInput
            small={false}
            id={"confirm" + props.car.id + idPrefix + "-4"}
            container_style={{ maxWidth: "40px" }}
            maxLength={1}
            onInput={(e: any) => update(4, e.target.value)}
          />
        </div>
        {timer > 0 && (
          <div className={"my-px-10 font-size-14 text-gray-color"}>
            Вы сможете запросить СМС через {timerToString()}
          </div>
        )}
        {timer <= 0 && (
          <div className={"my-px-10"}>
            <button
              className={
                "default-link text-default text-decoration-underline font-size-14"
              }
              onClick={props.repeatRequest}
            >
              Отправить СМС ещё раз
            </button>
          </div>
        )}
        {error.length > 0 && (
          <div className={"my-2 text-red-color font-size-14"}>{error}</div>
        )}
        {error_message && (
          <div className={"my-2 text-red-color font-size-14"}>
            {error_message}
          </div>
        )}
      </div>
      <div className={"d-flex justify-content-between"}>
        <button
          className={"site-btn small " + (!passed ? "dark" : "")}
          onClick={() => send()}
        >
          Подтвердить код
        </button>
        <button
          className={
            "default-link text-decoration-none default-transition text-gray-color text-hover-default"
          }
          onClick={() => props.setStep("start")}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &nbsp;&nbsp;&nbsp;Изменить номер
        </button>
      </div>
    </ModalTemplateContent>
  );
};

export const CarRentChooseeAddress: React.FC<{
  closeFunc: () => void;
  setStep: (string) => void;
  car: CarDataType;
  data: ConfirmPhone;
  setData: (CallRequestData) => void;
}> = (props) => {
  const [passed, setPassed] = useState(false);
  const [debounceTimerId, setDebounceTimerId] = useState<NodeJS.Timeout>();
  const [selectedAddress, setSelectedAddress] = useState<
    0 | 3 | 2 | 1 | undefined
  >(1);
  const addressOptions = [
    {
      id: 1,
      label: "СПб, Торфяная дорога, 7Ф, БЦ «Гулливер» 2 оф. 104",
    },
    {
      id: 2,
      label: "Товарищеский проспект, 20/27",
    },
    {
      id: 3,
      label: "Доставка автомобиля до адреса",
    },
  ];
  const send = () => {
    props.setStep("payment");
  };

  const update = (index: number, value: string) => {};

  const [deliveryAddress, setDeliveryAddress] = useState<string>("");

  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);

  const [newPoint, setNewPoint] = useState<{
    coordinates: [number, number];
    tooltip: string;
  }>();

  useEffect(() => {
    // Отменяем предыдущий таймер при каждом изменении состояния поиска
    clearTimeout(debounceTimerId);

    // Устанавливаем новый таймер для задержки перед выполнением refetch
    const timerId = setTimeout(() => {
      deliveryAddress.trim().length > 0 && refetch();
    }, 2000); // Задержка

    // Сохраняем идентификатор таймера в состоянии
    setDebounceTimerId(timerId);

    // Возвращаем функцию очистки для useEffect, которая будет вызвана при размонтировании компонента или изменении зависимости
    return () => clearTimeout(timerId);
  }, [deliveryAddress]);

  const { data, error, refetch } = useQuery({
    queryKey: ["delivery coordinates"],
    enabled: false,
    queryFn: () =>
      axios.get(
        `https://geocode-maps.yandex.ru/1.x/?apikey=5af93faa-7ddc-4d42-ad93-d93f6b01fd5a&geocode=${deliveryAddress.replaceAll(
          /\s/g,
          "+"
        )}&format=json`
      ),
  });

  useEffect(() => {
    data &&
      data.data.response?.GeoObjectCollection?.metaDataProperty
        .GeocoderResponseMetaData.found !== 0 &&
      setDeliveryAddress(
        data.data.response.GeoObjectCollection.featureMember[0].GeoObject.name
      );
    data &&
      setNewPoint({
        coordinates:
          data.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(
            " "
          ),
        tooltip:
          data.data.response.GeoObjectCollection.featureMember[0].GeoObject
            .name,
      });
  }, [data]);

  return (
    <ModalTemplateContent>
      <div>
        <div className={"mb-px-50"}>
          <button
            className={
              "default-link font-size-18 font-weight-semibold text-hover-default"
            }
            onClick={() => props.setStep("choose_date")}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp;&nbsp;ВЕРНУТЬСЯ
          </button>
        </div>
        <div>
          <div
            className={
              "call-content-text-header font-size-40 line-height-120 mb-px-10"
            }
          >
            Бронирование
            <br />
            {props.car.brand} {props.car.model}
          </div>
          <div className={"call-content-text font-size-16"}>
            <span className={"text-default"}>
              Укажите адрес откуда будете забирать автомобиль
            </span>
          </div>
        </div>
      </div>
      <div
        className={"d-flex flex-column gap-2 mt-px-10"}
        style={{ height: "100%" }}
      >
        {addressOptions.map((item) => {
          return (
            <div
              onClick={() =>
                setSelectedAddress(item.id as 0 | 2 | 1 | undefined)
              }
              className={`radio-input${
                selectedAddress === item.id ? "_checked" : ""
              }`}
            >
              {item.id === 3 ? (
                <div>
                  {item.label} <br />
                  <span style={{ color: "#BABCBF" }}>Стоимость: 3 000 ₽</span>
                </div>
              ) : (
                item.label
              )}
            </div>
          );
        })}
        {selectedAddress === 3 && (
          <ModalTemplateInput
            small={false}
            placeholder="Введите адрес"
            onChange={(e) => setDeliveryAddress(e.target.value)}
            value={deliveryAddress}
          />
        )}
      </div>
      <div
        className={`open-map-button${isMapOpen ? "_large" : ""}`}
        onClick={() => setIsMapOpen(!isMapOpen)}
      >
        <img src={mapFrameIcon} alt="map icon" />
        {isMapOpen ? "Закрыть карту" : "Открыть карту"}
      </div>
      <div className={`form-map_container${isMapOpen ? "_large" : ""}`}>
        <Map
          zoom={20}
          selected={selectedAddress}
          typeOfPoints="choices"
          pointsCallback={setSelectedAddress}
          setDeliveryAddress={setDeliveryAddress}
          newPoint={newPoint}
          setNewPoint={setNewPoint}
        />
      </div>
      <div className={"d-flex justify-content-between"}>
        <button
          className={"site-btn small " + (!passed ? "dark" : "")}
          onClick={() => send()}
        >
          Далее
        </button>
      </div>
    </ModalTemplateContent>
  );
};

export const CarRentPaymentButton: React.FC<{
  payment: string;
  error: boolean;
  image: string;
  text: string;
  code: string;
  setPayment: (string) => void;
}> = ({ payment, error, image, text, code, setPayment }) => {
  return (
    <button
      className={
        "car-rent-modal-btn " +
        (payment === code ? " selected" : "") +
        (error ? " error" : "")
      }
      onClick={() => setPayment(code)}
    >
      <div className={"car-rent-modal-btn-image"}>
        <img src={image} alt="" />
      </div>
      <div className={"car-rent-modal-btn-text"}>{text}</div>
    </button>
  );
};

const pay_koef = {
  // процеты оплаты
  card: 0.97,
  sbp: 0.99,
};
export const CarRentPaymentType: React.FC<{
  closeFunc: () => void;
  data: ConfirmPhone | any;
  setStep: (string) => void;
  car: CarDataType;
  deposit: number;
  type: "rent" | "taxi";
  setPaymentStatus: (e: RentBookingPaymentStatus) => void;
  setConfirmPayment: (e: ConfirmPaymentQR) => void;
  setDeposit: (e: number) => void;
  dates:
    | {
        StartDate: string;
        EndDate: string | null | undefined;
      }
    | undefined;
}> = (props) => {
  useEffect(() => {
    if (props.deposit === 0) {
      props.setPaymentStatus("CONFIRMED");
      props.setStep("booking_result");
    }
  }, [props.deposit]);
  const [payment, setPayment] = useState("");
  const [passed, setPassed] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [redButton, setRedButton] = useState(false);
  const { isAuthenticated } = useAuth();
  const [cardPrice] = useState(
    parseFloat((props.deposit / pay_koef.card).toFixed(2))
  );
  const [sbpPrice] = useState(
    parseFloat((props.deposit / pay_koef.sbp).toFixed(2))
  );

  const send = async () => {
    if (payment === "") {
      setError("Выберите способ оплаты");
      setPassed(false);
      setRedButton(true);
      return;
    }
    setRedButton(false);
    try {
      const res = await api.get(
        `/voshod-auto/?w=pay&summ=${
          payment === "sbp" ? sbpPrice : cardPrice
        }&payment=${payment === "card" ? "classic" : "sbp"}&car_id=${
          props.car.id
        }${
          props.dates &&
          `&start=${props.dates.StartDate}${
            props.dates.EndDate !== undefined
              ? `&end=${props.dates.EndDate}`
              : ""
          }`
        }&type=${props.type}&device=${isMobile ? "mobile" : "desktop"}`,
        { withCredentials: true }
      );

      if (res.data.result === 1) {
        if (payment === "sbp") {
          res.data.qr
            ? props.setConfirmPayment({ qr: res.data.qr, pid: res.data.pid })
            : window.location.replace(res.data.redirect);
          props.setStep("confirm_payment");
        } else if (payment === "card") {
          window.location.replace(res.data.redirect);
        }
      }
    } catch (error) {
      setError(
        (error as AxiosError<ErrorResponse>).response?.data.message ??
          "Возникла ошибка с сервером поробуйте позже"
      );
    }
  };
  const update = (ptype) => {
    if (ptype === "sbp") {
      props.setDeposit(sbpPrice);
    } else if (ptype === "card") {
      props.setDeposit(cardPrice);
    }
    setPayment(ptype);
    setRedButton(false);
    setPassed(true);
    setError("");
  };

  return (
    <ModalTemplateContent>
      <div>
        <div className={"mb-px-90"}>
          <button
            className={
              "default-link font-size-18 font-weight-semibold text-hover-default"
            }
            onClick={() =>
              isAuthenticated ? props.closeFunc() : props.setStep("start")
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp;&nbsp;ВЕРНУТЬСЯ
          </button>
        </div>
        <div className={"mb-px-20"}>
          <div
            className={
              "call-content-text-header font-size-40 line-height-120 mb-px-10"
            }
          >
            Бронирование
            <br />
            {props.car.brand} {props.car.model}
          </div>
          <div
            className={
              "font-size-16 line-height-140 font-weight-medium mb-px-40"
            }
          >
            Выберите форму оплаты
          </div>
          <div
            className={
              "text-default font-size-16 line-height-140 font-weight-semibold mb-px-5"
            }
          >
            К оплате:
          </div>
          <div
            className={
              "text-default font-size-32 line-height-140 font-weight-semibold"
            }
          >
            {props.deposit} ₽
          </div>
        </div>
      </div>
      <div>
        <div className={"d-flex flex-column w-100 gap-y-px-15"}>
          <CarRentPaymentButton
            payment={payment}
            error={redButton}
            image={sbpImg}
            text={"СБП"}
            code={"sbp"}
            setPayment={update}
          />
          <CarRentPaymentButton
            payment={payment}
            error={redButton}
            image={bankCardImg}
            text={"Банковская карта"}
            code={"card"}
            setPayment={update}
          />
        </div>
        <div className={"my-2 text-red-color font-size-12"}>
          {error || <>&nbsp;</>}
        </div>
      </div>
      <div>
        <button
          className={"site-btn small " + (!passed ? "dark" : "")}
          onClick={send}
        >
          Перейти к оплате
        </button>
      </div>
    </ModalTemplateContent>
  );
};

export const CarRentChooseDate: React.FC<{
  closeFunc: () => void;
  data: ConfirmPhone | any;
  setStep: (string) => void;
  car: CarDataType;
  deposit: number;
  setConfirmPayment: (e: ConfirmPaymentQR) => void;
  setDeposit: (e: number) => void;
  getPriceCar: () => void;
  setDates: React.Dispatch<
    React.SetStateAction<{
      StartDate: string;
      EndDate: string | null | undefined;
    }>
  >;
}> = (props) => {
  // ЛОКАЛЬНЫЕ СОСТОЯНИЯ
  const [passed, setPassed] = useState(false);
  const [error, setError] = useState<null | string>(null);
  // Массив недоступных дат
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);
  // Хук для хранения начала открытого интервала
  const openIntervalStart = useRef<Date | null>(null);

  // ЗАПРОСЫ К АПИ
  const getRangefromAPI = async () => {
    const carId = props.car.id;
    try {
      const res = await api.get(
        `/voshod-auto/?w=get-book-car-interval&car_id=${carId}&type=free`,
        { withCredentials: true }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  // ЗАВИСИМОСТИ
  useEffect(() => {
    // Если есть недоступные интервалы
    if (props.car.unavailable_intervals) {
      // Устанавливаем start и end, используя ближайшую доступную дату
      const today = new Date();
      const closestStart = findClosestAvailableDate(
        today,
        getDynamicExcludeDatesWithoutLast({
          unavailableIntervals: props.car.unavailable_intervals,
        })
      );
      const closestEnd = findClosestAvailableDate(
        addDays(closestStart, 1),
        getDynamicExcludeDatesWithoutLast({
          unavailableIntervals: props.car.unavailable_intervals,
        })
      );

      // Устанавливаем найденные даты в локальное состояние
      setDates({
        startDate: closestStart,
        endDate: closestEnd,
        key: "selection",
      });

      // Устанавливам недоступные даты без последнего дня у каждого интервала
      // Потому в последний день недоступного интервала авто уже будет свободно
      setDisabledDates(
        getDynamicExcludeDatesWithoutLast({
          unavailableIntervals: props.car.unavailable_intervals,
        })
      );
    }
  }, [props.car]);

  // Функция для поиска ближайшей доступной даты
  function findClosestAvailableDate(start: Date, disabledDates: Date[]): Date {
    // Начинаем со start, затем будем увеличивать пока не найдем дату, которой нет в disabledDates
    let currentDate = start;
    // Ищем ближайшую доступную дату
    while (disabledDates.some((date) => isSameDay(date, currentDate))) {
      currentDate = addDays(currentDate, 1); // Перемещаемся на следующий день
    }
    return currentDate;
  }

  // Функция для получения недоступных дат с исключением последнего дня интервала
  const getDynamicExcludeDatesWithoutLast = ({
    unavailableIntervals,
  }: {
    unavailableIntervals: {
      StartDate: string;
      EndDate?: string;
      Type: string;
    }[];
  }): Date[] => {
    // Берем интервалы
    return unavailableIntervals.flatMap((interval) => {
      // Дата начала интервала есть всегда
      const startDate = parseISO(interval.StartDate);
      // Дата конца интервала может быть открытой
      const endDate = interval.EndDate ? parseISO(interval.EndDate) : undefined;
      // Если дата действительно открыта
      if (!endDate) {
        // Предполагается, что открытый интервал может быть только одним, иначе они наложаться друг на друга
        // Сохраняем его начало в ref
        openIntervalStart.current = startDate;
        return []; // Возвращаем пустой массив, чтобы исключить открытый интервал
      } else {
        // Убираем последний день интервала и возвращаем даты
        return getDatesBetween(
          startDate.toISOString(),
          addDays(endDate, -1).toISOString()
        );
      }
    });
  };

  // Ищет наиболее дальнюю свободную дату
  function findFarthestAvailableDate(start: Date, end: Date): Date {
    let farthestDate = start;
    // Итерируемся по всем датам между start и end
    let currentDate = start;
    while (isBefore(currentDate, end)) {
      currentDate = addDays(currentDate, 1);
      // Если текущая дата доступна, обновляем farthestDate
      if (!disabledDates.some((date) => isSameDay(date, currentDate))) {
        farthestDate = currentDate;
      } else {
        // Если наткнулись на недоступную дату, прерываем цикл
        break;
      }
    }
    return farthestDate; // Возвращаем последнюю доступную дату
  }

  // Генерация дат между StartDate и EndDate
  // Принимает интеравал строк, возвращает массив недоступных дат
  const getDatesBetween = (startDate: string, endDate: string): Date[] => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates: Date[] = [];

    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      dates.push(new Date(date));
    }

    return dates;
  };

  // Функция для получения недоступных дат, с исключением первого дня ближайшего интервала
  // Вызывается, если есть начало интервала, но пока нет окончания.
  // В таком случае для сдачи авто доступна та дата, в которую ее забирают в следующем интервале.
  const getDynamicExcludeDates = ({
    unavailableIntervals,
    selectedDate,
  }: {
    unavailableIntervals: {
      StartDate: string;
      EndDate?: string;
      Type: string;
    }[];
    selectedDate: Date;
  }): Date[] => {
    const selected = selectedDate;

    return unavailableIntervals
      .flatMap((interval) => {
        const startDate = parseISO(interval.StartDate);
        const endDate = interval.EndDate
          ? parseISO(interval.EndDate)
          : undefined;

        if (!endDate) {
          // Сохраняем начало открытого интервала
          openIntervalStart.current = startDate;
          return []; // Пропускаем открытые интервалы
        }

        // Если выбранная дата перед началом интервала
        if (isBefore(selected, startDate)) {
          return getDatesBetween(
            addDays(startDate, 1).toISOString(), // Исключаем первый день
            endDate.toISOString()
          );
        }

        // Если выбранная дата после интервала или внутри интервала
        return getDatesBetween(startDate.toISOString(), endDate.toISOString());
      })
      .filter((date) => !isSameDay(date, selected)); // Исключаем выбранную дату
  };

  const busyDates = props.car.unavailable_intervals
    ? props.car.unavailable_intervals.map((dates) => {
        return {
          from: format(parseISO(dates.StartDate), "dd.MM.yyyy"),
          to:
            dates.EndDate === null
              ? null
              : format(parseISO(dates.EndDate), "dd.MM.yyyy"),
        };
      })
    : [];

  // Функция принимает возвращает корректную дату принимая строку
  const parseDate = (dateString: string): Date => {
    const [day, month, year] = dateString.split(".");
    if (
      !day ||
      !month ||
      !year ||
      isNaN(+day) ||
      isNaN(+month) ||
      isNaN(+year)
    ) {
      throw new Error(`Неверный формат даты: ${dateString}`);
    }
    const date = new Date(`${month}.${day}.${year}`);
    if (isNaN(date.getTime())) {
      throw new Error(`Невозможно создать дату из строки: ${dateString}`);
    }
    return date;
  };

  const getDatesArray = (busyDates: { from: string; to: string | null }[]) => {
    const allDates: Date[] = [];

    busyDates.forEach(({ from, to }) => {
      const startDate = parseDate(from);

      if (to === null) {
        // Открытый интервал — сохраняем его начало и пропускаем
        openIntervalStart.current = new Date(startDate);
        return;
      }

      const endDate = parseDate(to);
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        allDates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    });

    return allDates;
  };

  const isSameDate = (date1: Date, date2: Date) => {
    return (
      date1 &&
      date2 &&
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isDisabled = (day: Date): boolean => {
    const busyDatesArray = disabledDates;

    // Получаем текущую дату без времени
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Блокируем день, если он в прошлом
    if (day.getTime() < today.getTime()) {
      return true;
    }

    // Проверяем, относится ли день к открытому интервалу
    if (
      openIntervalStart.current &&
      day.getTime() >= openIntervalStart.current.getTime()
    ) {
      return true;
    }

    // Проверяем, совпадает ли день с одной из заблокированных дат
    if (busyDatesArray.some((busyDate) => isSameDate(busyDate, day))) {
      return true;
    }

    // Если ни одно условие не сработало, день не заблокирован
    return false;
  };

  const isDisabledRange = (
    startDate: Date | null,
    endDate: Date | null
  ): boolean => {
    if (!startDate || !endDate) return true; // Если диапазон невалидный, он считается недоступным

    let currentDate = new Date(startDate); // Начинаем с начальной даты
    while (currentDate <= endDate) {
      if (isDisabled(currentDate)) {
        return true; // Если хотя бы одна дата недоступна, весь диапазон недоступен
      }
      currentDate.setDate(currentDate.getDate() + 1); // Переходим к следующему дню
    }

    return false; // Все даты в диапазоне доступны
  };

  const send = () => {
    props.getPriceCar();
    // props.setStep("address");
  };

  const [dates, setDates] = useState<{
    startDate: Date;
    endDate: Date | undefined;
    key: string;
  }>({
    startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    key: "selection",
  });

  useEffect(() => {
    const startDate = format(dates.startDate, "yyyy-MM-dd");
    const endDate = dates.endDate && format(dates.endDate, "yyyy-MM-dd");

    const datesToSet: {
      StartDate: string;
      EndDate: string | undefined | null;
    } = {
      StartDate: startDate,
      EndDate: undefined,
    };
    if (startDate !== endDate) {
      datesToSet.EndDate = endDate;
    }

    props.setDates(datesToSet);
  }, []);

  // Валиден ли выбранный массив, то есть нет ли внутри него недоступных дат
  const isRangeValid = ({
    start,
    end,
  }: {
    start: Date;
    end: Date;
  }): boolean => {
    if (!start || !end) return true;

    // Список всех дат между start и end
    const datesInRange = getDatesBetween(
      start.toISOString(),
      end.toISOString()
    );

    // Проверяем, есть ли пересечения с disabledDates
    return !datesInRange.some((date) =>
      disabledDates.some((excluded) => isSameDay(date, excluded))
    );
  };

  const handleSelect = (ranges) => {
    const start = ranges.selection.startDate;
    const end = ranges.selection.endDate;

    // Если выбрана только дата начала
    if (start === end) {
      // Разблокирована ближайшая дата недоступного интервала
      setDisabledDates(
        getDynamicExcludeDates({
          unavailableIntervals: props.car.unavailable_intervals,
          selectedDate: start,
        })
      );
      setDates({ startDate: start, endDate: undefined, key: "selection" });
      props.setDates({
        StartDate: format(start, "yyyy-MM-dd"),
        EndDate: undefined,
      });
      return;
    }

    // Если выбраны обе даты
    if (start !== end) {
      // Получаем текущую дату в формате только числа (год, месяц, день)
      const today = new Date();
      const todayOnlyDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );

      // Преобразуем start в формат только числа
      const startOnlyDate = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate()
      );

      // Проверяем, находится ли дата начала в недоступных датах или является датой в прошлом
      if (
        start &&
        (disabledDates.some((date) => isSameDay(date, start)) ||
          isBefore(startOnlyDate, todayOnlyDate))
      ) {
        console.log(
          "Выбранная дата начала находится в недоступных датах или в прошлом. Прерываем."
        );
        return; // Прерываем выполнение функции
      }
      // Проверяем валидность диапазона
      if (
        !isRangeValid({
          start,
          end,
        })
      ) {
        // Если диапазон невалиден, находим ближайшую доступную дату
        const adjustedEnd = findFarthestAvailableDate(start, end);
        // Устанавливаем диапазон с новой датой окончания
        setDates({ startDate: start, endDate: adjustedEnd, key: "selection" });
        props.setDates({
          StartDate: format(start, "yyyy-MM-dd"),
          EndDate: format(adjustedEnd, "yyyy-MM-dd"),
        });
      } else {
        // Диапазон валиден
        setDisabledDates(
          getDynamicExcludeDatesWithoutLast({
            unavailableIntervals: props.car.unavailable_intervals,
          }).filter((date) => !isSameDay(date, start) && !isSameDay(date, end))
        );
        setDates({ startDate: start, endDate: end, key: "selection" });
        props.setDates({
          StartDate: format(start, "yyyy-MM-dd"),
          EndDate: format(end, "yyyy-MM-dd"),
        });
      }
    }
  };

  // Функция для проверки, пересекается ли диапазон с недоступными датами
  const isDateRangeOverlapping = (startDate: Date, endDate: Date) => {
    return getDatesArray(busyDates).some((busyDate) => {
      return (
        (busyDate >= startDate && busyDate <= endDate) ||
        (busyDate >= startDate && busyDate <= endDate)
      );
    });
  };

  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "3 дня",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 3)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "Неделю",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "2 недели",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 14)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "Месяц",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(new Date().setDate(new Date().getDate() + 29)),
        }),
        isDisabled: function () {
          const { startDate, endDate } = this.range();
          return isDisabledRange(startDate, endDate);
        },
        isSelected: function () {
          const { startDate, endDate } = this.range();
          return (
            isSameDate(dates.startDate, startDate) &&
            dates.endDate &&
            isSameDate(dates.endDate, endDate)
          );
        },
      },
      {
        label: "Другая дата",
        range: () => ({
          startDate: new Date(),
          endDate: new Date(),
        }),
        isDisabled: function () {
          return false;
        },
        isSelected: function () {
          // Если ни один другой диапазон не выбран, возвращаем true для "Другой даты"
          return !customDateObjects
            .slice(0, 4)
            .map((option) => option.isSelected())
            .includes(true);
        },
      },
    ];

    return customDateObjects;
  };

  const SideBar = sideBarOptions();
  const StaticRanges = createStaticRanges(
    SideBar.filter((range) => !range.isDisabled())
  );

  return (
    <ModalTemplateContent>
      <div>
        <div className={"mb-px-20"}></div>
        <div className={"mb-px-20"}>
          <div
            className={
              "call-content-text-header font-size-40 line-height-120 mb-px-10"
            }
          >
            Бронирование
            <br />
            {props.car.brand} {props.car.model}
          </div>

          <div className={"font-size-16 line-height-140 font-weight-medium"}>
            Выберите даты бронирования
          </div>
        </div>
      </div>
      <div className="form-datepicker_container">
        <DateRangePicker
          className="d-flex flex-row-reverse"
          locale={ru}
          onChange={handleSelect}
          ranges={[dates]}
          weekdayDisplayFormat={"EEEEEE"}
          shownDate={new Date()}
          disabledDay={(day) => isDisabled(day)}
          minDate={new Date()}
          staticRanges={StaticRanges}
          showMonthAndYearPickers={false}
          dayContentRenderer={(day) =>
            isDisabled(day) ? (
              <OverlayTrigger
                rootClose={true}
                trigger="click"
                placement="bottom"
                overlay={
                  <Tooltip>Невозможно забронировать выбранную дату</Tooltip>
                }
              >
                <span>{day.getDate()}</span>
              </OverlayTrigger>
            ) : (
              <span>{day.getDate()}</span>
            )
          }
        />
        <div className={"my-2 text-red-color font-size-12"}>
          {error || <>&nbsp;</>}
        </div>
      </div>
      <div className="mb-px-10 mt-px-10">
        <span
          style={{ color: "#BABCBF", fontWeight: "500", fontStyle: "16px" }}
        >
          Выбранные даты:
        </span>
        <div className={"d-flex gap-3 fw-bold"}>
          <span>{format(dates.startDate, "dd.MM.yyyy")}</span>
          <span style={{ color: "#BABCBF" }}>-</span>
          <span
            style={{
              color: dates.startDate === dates.endDate ? "#BABCBF" : "black",
            }}
          >
            {dates.startDate === dates.endDate || dates.endDate === undefined
              ? "..."
              : format(dates.endDate, "dd.MM.yyyy")}
          </span>
        </div>

        <div className="return_atention">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM13.0083 7.09284C13.0083 6.53729 12.5579 6.08692 12.0024 6.08692C11.4468 6.08692 10.9965 6.53729 10.9965 7.09284V12.0019C10.9965 12.5575 11.4468 13.0078 12.0024 13.0078C12.5579 13.0078 13.0083 12.5575 13.0083 12.0019V7.09284ZM13.0083 16.9108C13.0083 16.3552 12.5579 15.9048 12.0024 15.9048C11.4468 15.9048 10.9965 16.3552 10.9965 16.9108V17.4562C10.9965 18.0118 11.4468 18.4621 12.0024 18.4621C12.5579 18.4621 13.0083 18.0118 13.0083 17.4562V16.9108Z"
              fill="#222222"
            />
          </svg>

          {`Автомобиль необходимо сдать ${
            dates.endDate !== undefined
              ? format(dates.endDate, "dd.MM.yyyy")
              : ""
          } с 9.00 до 11.00`}
        </div>
      </div>
      <div>
        <button
          className={"site-btn small " + (!passed ? "dark" : "")}
          onClick={() => send()}
        >
          Далее
        </button>
      </div>
    </ModalTemplateContent>
  );
};

export const CarRentPaymentTypeConfirm: React.FC<{
  closeFunc: () => void;
  data: ConfirmPhone | any;
  setStep: (e: CarBookingStepsType) => void;
  step: CarBookingStepsType;
  car: CarDataType;
  deposit: number;
  setDeposit: (e: number) => void;
  confirmPayment: ConfirmPaymentQR;
  setPaymentStatus: (e: RentBookingPaymentStatus) => void;
  paymentStatus: RentBookingPaymentStatus;
  setCarName: (e: string) => void;
}> = (props) => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (props.paymentStatus !== "CONFIRMED" || "REFUNDED" || "CANCELLED") {
      const interval = setInterval(() => {
        api
          .get(`/voshod-auto/?w=check-pay&pid=${props.confirmPayment.pid}`)
          .then((res) => {
            if (res.data.result === 1) {
              props.setPaymentStatus(res.data.status);
              props.setCarName(res.data.car);
              if (
                res.data.status === "CONFIRMED" ||
                res.data.status === "REFUNDED" ||
                res.data.status === "CANCELLED"
              ) {
                props.setStep("booking_result");
              }
            }
          })
          .catch((error) => {
            console.log(error);
            props.setPaymentStatus(null);
            props.setStep("payment");
          });
      }, 5000);
      return () => {
        clearInterval(interval); // stops interval
      };
    }
  }, [props.paymentStatus]);

  return (
    <ModalTemplateContent>
      <div>
        <div className={"mb-px-90"}>
          <button
            className={
              "default-link font-size-18 font-weight-semibold text-hover-default"
            }
            onClick={() => props.setStep(isAuthenticated ? "payment" : "start")}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp;&nbsp;ВЕРНУТЬСЯ
          </button>
        </div>
        <div className={"mb-px-20"}>
          <div
            className={
              "call-content-text-header font-size-40 line-height-120 mb-px-10"
            }
          >
            Бронирование
            <br />
            {props.car.brand + " " + props.car.model}
          </div>
          <div
            className={
              "font-size-16 line-height-140 font-weight-medium mb-px-40"
            }
          >
            Выберите форму оплаты
          </div>
          <div
            className={
              "text-default font-size-16 line-height-140 font-weight-semibold mb-px-5"
            }
          >
            К оплате:
          </div>
          <div
            className={
              "text-default font-size-32 line-height-140 font-weight-semibold"
            }
          >
            {props.deposit} ₽
          </div>
        </div>
      </div>
      <div>
        <div className={"d-flex justify-content-center w-100"}>
          <img
            src={`data:image/svg+xml;utf8,${
              props.confirmPayment.qr &&
              encodeURIComponent(props.confirmPayment.qr)
            }`}
            alt=""
            width={200}
            height={200}
          />
        </div>
      </div>
      <div style={{ height: "40px" }}>
        <div className={"my-2 text-red-color font-size-12"}>
          {/* {error || <>&nbsp;</>} */}
        </div>
      </div>
    </ModalTemplateContent>
  );
};

export const CarbookingSuccess: React.FC<{
  closeFunc: () => void;
  car: CarDataType;
  carName: string | null;
}> = (props) => {
  return (
    <ModalTemplateContent>
      <div style={{ marginTop: "162px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.667969 29.9998C0.667969 13.7995 13.801 0.666504 30.0013 0.666504C46.2017 0.666504 59.3346 13.7995 59.3346 29.9998C59.3346 46.2002 46.2017 59.3332 30.0013 59.3332C13.801 59.3332 0.667969 46.2002 0.667969 29.9998Z"
            fill="#008F4B"
          />
          <path
            d="M20.2617 30.6319L26.9574 36.956L39.74 23.043"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className={"call-content-text-header mt-px-30"}>
          Оплата <br />
          прошла успешно!
        </div>
        <div className={"call-content-text"}>
          {props.carName || props.car.brand + " " + props.car.model} —
          забронирован!
        </div>
      </div>
      <div>
        <button className={"site-btn small"} onClick={props.closeFunc}>
          Закрыть
        </button>
      </div>
    </ModalTemplateContent>
  );
};

export const CarRentBookingStatus: React.FC<{
  paymentStatus: RentBookingPaymentStatus;
  closeFunc: () => void;
  car: CarDataType;
  carName: string | null;
}> = (props) => {
  return (
    <ModalTemplateContent>
      <div style={{ marginTop: "162px" }}>
        {props.paymentStatus === "CONFIRMED" ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.667969 29.9998C0.667969 13.7995 13.801 0.666504 30.0013 0.666504C46.2017 0.666504 59.3346 13.7995 59.3346 29.9998C59.3346 46.2002 46.2017 59.3332 30.0013 59.3332C13.801 59.3332 0.667969 46.2002 0.667969 29.9998Z"
                fill="#008F4B"
              />
              <path
                d="M20.2617 30.6319L26.9574 36.956L39.74 23.043"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className={"call-content-text-header mt-px-30"}>
              Оплата <br />
              прошла успешно!
            </div>
            <div className={"call-content-text"}>
              {props.carName || props.car.brand + " " + props.car.model} —
              забронирован!
            </div>
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.0013 0.666504C13.801 0.666504 0.667969 13.7995 0.667969 29.9998C0.667969 46.2002 13.801 59.3332 30.0013 59.3332C46.2017 59.3332 59.3346 46.2002 59.3346 29.9998C59.3346 13.7995 46.2017 0.666504 30.0013 0.666504ZM23.4155 20.5856C22.6345 19.8046 21.3681 19.8046 20.5871 20.5856C19.806 21.3667 19.806 22.633 20.5871 23.4141L27.1729 29.9998L20.5871 36.5856C19.806 37.3667 19.806 38.633 20.5871 39.4141C21.3681 40.1951 22.6345 40.1951 23.4155 39.4141L30.0013 32.8283L36.5871 39.4141C37.3681 40.1951 38.6345 40.1951 39.4155 39.4141C40.1966 38.633 40.1966 37.3667 39.4155 36.5856L32.8297 29.9998L39.4155 23.4141C40.1966 22.633 40.1966 21.3667 39.4155 20.5856C38.6345 19.8046 37.3681 19.8046 36.5871 20.5856L30.0013 27.1714L23.4155 20.5856Z"
                fill="#BF3535"
              />
            </svg>
            <div className={"call-content-text-header mt-px-30"}>
              Оплата <br /> была прервана
            </div>
            <div className={"call-content-text"}>
              {props.paymentStatus === "REFUNDED"
                ? "Cредства возвращены"
                : props.car.brand + " " + props.car.model}
            </div>
          </>
        )}
      </div>
      <div>
        <button className={"site-btn small"} onClick={props.closeFunc}>
          Закрыть
        </button>
      </div>
    </ModalTemplateContent>
  );
};

export const CarRentFormConfirmed: React.FC<{ closeFunc: () => void }> = (
  props
) => {
  return (
    <ModalTemplateContent>
      <div style={{ marginTop: "130px" }}>
        <div className={"call-content-text-header"}>
          Спасибо <br />
          за обращение
        </div>
        <div className={"call-content-text"}>
          Наш специалист с вами свяжется
        </div>
        <div className={"call-content-text"}>Пожалуйста, ожидайте</div>
        <div
          style={{ width: "100px", height: "6px", margin: "20px 0" }}
          className={"bg-red-color"}
        ></div>
      </div>
      <div>
        <button className={"site-btn small"} onClick={props.closeFunc}>
          Закрыть
        </button>
      </div>
    </ModalTemplateContent>
  );
};

export const CarRequestFormImage: React.FC<{
  closeFunc: () => void;
  car: CarDataType;
}> = (props) => {
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleSelect = (selectedIndex) => {
    if (Api.isError(props.car)) return;
    if (selectedIndex >= (props.car?.images?.length ?? 0)) selectedIndex = 0;
    if (selectedIndex < 0) selectedIndex = (props.car?.images?.length ?? 1) - 1;
    setIndex(selectedIndex);
  };

  if (!props.car)
    return (
      <div
        className={
          "d-flex flex-column position-relative justify-content-center align-items-center"
        }
        style={{ minWidth: "600px", maxWidth: "600px" }}
      >
        <Loader />
      </div>
    );
  if (Api.isError(props.car))
    return (
      <div
        className={
          "d-flex flex-column position-relative justify-content-center align-items-center"
        }
        style={{ minWidth: "600px", maxWidth: "600px" }}
      >
        <LoadError response={props.car} />
      </div>
    );

  return (
    <div
      className={"d-flex flex-column position-relative justify-content-center"}
      style={{ minWidth: "600px", maxWidth: "600px" }}
    >
      <CarImagesModal
        show={showModal}
        car_images={props.car.images}
        handleClose={handleClose}
        index={index}
      />
      <div className={"car-images mb-0"}>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          controls={false}
          indicators={false}
        >
          {props.car.images.map((img, index) => (
            <Carousel.Item key={img.id}>
              <div
                className={"car-images-image-container cursor-pointer"}
                onClick={handleShow}
              >
                <img
                  className="d-block w-100 car-images-image"
                  src={img.image}
                  alt=""
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div
        className={
          "position-absolute bottom-0 left-0 w-100 d-flex justify-content-center p-3"
        }
      >
        <div className={"car-images-controls w-100"}>
          <button
            className={"car-images-controls-btn"}
            onClick={() => handleSelect(index - 1)}
          >
            <img src={caretLeft} width={16} height={32} alt="" />
          </button>
          <div className={"car-images-controls-sliders"}>
            {[...new Array(props.car.images.length)].map((i, ind) => (
              <div
                key={ind}
                className={
                  "car-images-controls-slider " +
                  (index === ind ? "active" : "")
                }
                onClick={() => handleSelect(ind)}
              >
                <div></div>
              </div>
            ))}
          </div>
          <button
            className={"car-images-controls-btn"}
            onClick={() => handleSelect(index + 1)}
          >
            <img src={caretRight} width={16} height={32} alt="" />
          </button>
        </div>
      </div>
      <div className={"position-absolute top-0 left-0 px-px-30 py-px-20"}>
        <button
          className={
            "default-link font-size-18 font-weight-semibold text-decoration-none"
          }
          onClick={() => props.closeFunc()}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &nbsp;&nbsp;НАЗАД
        </button>
      </div>
    </div>
  );
};

export const CarRentCreateAccount: React.FC<{
  closeFunc: () => void;
  setStep: (string) => void;
  car: CarDataType;
  setData: (CallRequestData) => void;
  data: ConfirmPhone | CallRequestData;
  closeOnBack?: boolean;
  getPayment: () => void;
}> = (props) => {
  const [base64, setBase64] = useState("");
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [state, setState] = useState<RentCreateAccountForm>({
    name: "",
    lastName: "",
    middleName: "",
    image: "",
    errors: {},
  });
  const [passed, setPassed] = useState(false);
  const createAccount = async () => {
    let errors = Utils.validateRentCreateAccont(state);

    if (Object.keys(errors).length > 0) {
      setState({ ...state, errors: errors });
      setPassed(false);
      return;
    }

    try {
      const res = await api.post("/voshod-auto/?w=update-profile", {
        body: JSON.stringify({
          w: "update-profile",
          first_name: state.name,
          last_name: state.lastName,
          middle_name: state.middleName,
          license_photo: base64,
        }),
      });

      if (res.data.result === 1) {
        props.getPayment();
      }
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<ErrorResponse>).response?.data.message ??
          "Возникла ошибка с сервером поробуйте позже"
      );
    }
  };

  const updateForm = (field: string, value: any) => {
    let errors = state.errors;
    delete errors[field];
    let newData = { ...state, [field]: value, errors: errors };
    setState(newData);
    errors = Utils.validateRentCreateAccont(newData);
    setPassed(Object.keys(errors).length === 0);
  };

  return (
    <ModalTemplateContent>
      <div>
        <div className={"mb-px-90"}>
          <button
            className={
              "default-link font-size-18 font-weight-semibold text-hover-default"
            }
            onClick={props.closeFunc}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &nbsp;&nbsp;ВЕРНУТЬСЯ
          </button>
        </div>
        <div>
          <div
            className={
              "call-content-text-header font-size-40 line-height-120 mb-px-10"
            }
          >
            Бронирование
            <br />
            {props.car.brand} {props.car.model}
          </div>
          <div className={"call-content-text font-size-16"}>
            Оставьте свой номер телефона,
            <br />
            для регистрации и оплаты бронирования
          </div>
        </div>
      </div>
      <div>
        <ModalTemplateInput
          placeholder="Фамилия"
          value={state.lastName}
          error={state.errors?.lastName}
          small={false}
          onChange={(e) => updateForm("lastName", e.target.value)}
          onInput={(e) => updateForm("lastName", e.target.value)}
        />
        <ModalTemplateInput
          placeholder="Имя"
          value={state.name}
          error={state.errors?.name}
          small={false}
          onChange={(e) => updateForm("name", e.target.value)}
          onInput={(e) => updateForm("name", e.target.value)}
        />
        <FileInput upload={setBase64} />
      </div>
      <button
        className={"site-btn small " + (!passed ? "dark" : "")}
        onClick={() => createAccount()}
      >
        Перейти к оплате
      </button>
    </ModalTemplateContent>
  );
};
