import call from "../../../../images/common/phone-call.svg";
import back from "../../../../images/common/back.svg";
import { HeaderLogoImage } from "../../../layout/Header";
import { useEffect, useState } from "react";
import { CarBookingStepsType } from "../../CarRentForm";
import {
  CarDataType,
  RentBookingPaymentStatus,
} from "../../../../types/RentTypes";
import RentModalMobileStart from "./steps/RentModalMobileStart";
import RentModalMobileConfirm from "./steps/RentModalMobileConfirm";
import RentModalMobileCreate from "./steps/RentModalMobileCreate";
import RentModalMobilePayment from "./steps/RentModalMobilePayment";
import RentModalMobileFinish from "./steps/RentModalMobileFinish";
import { ConfirmPaymentQR } from "../../../../types/AuthContextTypes";
import RentModalMobileConfirmPayment from "./steps/RentModalMobileConfirmPayment";
import RentModalMobilePaymentResult from "./steps/RentModalMobilePaymentResult";
import { RentModalMobileDate } from "./steps/RentModalMobileDate";
import { RentModalMobileAddress } from "./steps/RentModalMobileAddress";
import RentModalMobilePaymentSuccess from "./steps/RentModalMobilePaymentSuccess";

export const RentModalMobile = ({
  active,
  setActive,
  car,
  step,
  setStep,
  depositPrice,
  setDepositPrice,
  getPriceCar,
  paymentStatus,
  setPaymentStatus,
  carName,
  setCarName,
  setDates,
  dates,
  type,
}: {
  depositPrice: number;
  setDepositPrice: (e: number) => void;
  active: boolean;
  setActive: (e: boolean) => void;
  step: CarBookingStepsType;
  setStep: (e: CarBookingStepsType) => void;
  car: CarDataType;
  getPriceCar: () => void;
  paymentStatus: RentBookingPaymentStatus;
  setPaymentStatus: (e: RentBookingPaymentStatus) => void;
  carName: string | null;
  setCarName: (e: string) => void;
  type: "rent" | "taxi";
  dates:
    | {
        StartDate: string;
        EndDate: string | null | undefined;
      }
    | undefined;
  setDates: React.Dispatch<React.SetStateAction<{
    StartDate: string;
    EndDate: string | null | undefined;
}>>;
}) => {
  const [data, setData] = useState({
    phone: "",
    confirm: true,
    errors: {},
  });

  const [timer, setTimer] = useState(0);
  const [confirmPaymentQR, setConfirmPaymentQR] = useState<ConfirmPaymentQR>({
    qr: "",
    pid: "",
    redirect: "",
  });

  const [stepsHistory, setStepsHistory] = useState<CarBookingStepsType[]>([
    step,
  ]);

  const prevStep = () => {
    if (stepsHistory.length > 0) {
      const lastStep = stepsHistory[stepsHistory.length - 1]; // Получаем последний шаг
      setStepsHistory((prev) => prev.slice(0, -1)); // Удаляем последний шаг из истории
      setStep(lastStep); // Устанавливаем его как текущий
    } else {
      setActive(false);
    }
  };

  useEffect(() => {
    setStepsHistory((prev) => [...prev, step]); // Сохраняем текущий шаг в истории
  }, [step]);

  useEffect(() => {
    if (active) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [active]);

  const renderSteps = () => {
    switch (step) {
      case "start":
        return (
          <RentModalMobileStart
            setTimer={setTimer}
            data={data}
            setData={setData}
            car={car}
            setStep={setStep}
          />
        );
      case "confirm":
        return (
          <RentModalMobileConfirm
            step={step}
            getPayment={getPriceCar}
            setStep={setStep}
            car={car}
            phone={data.phone}
            timerConfirm={timer}
          />
        );
      case "choose_date":
        return (
          <RentModalMobileDate
            step={step}
            car={car}
            setStep={setStep}
            prevStep={prevStep}
            propsSetDates={setDates}
            getPriceCar={getPriceCar}
          />
        );
      case "address":
        return (
          <RentModalMobileAddress
            step={step}
            car={car}
            setStep={setStep}
            prevStep={prevStep}
          />
        );
      case "create":
        return (
          <RentModalMobileCreate
            getPayment={getPriceCar}
            step={step}
            car={car}
            setStep={setStep}
          />
        );
      case "payment":
        return (
          <RentModalMobilePayment
            setConfirmPayment={setConfirmPaymentQR}
            setPaymentStatus={setPaymentStatus}
            deposit={depositPrice}
            setDeposit={setDepositPrice}
            data={data}
            car={car}
            setStep={setStep}
            dates={dates}
            type={type}
          />
        );
      case "confirm_payment":
        return (
          <RentModalMobileConfirmPayment
            setCarName={setCarName}
            paymentStatus={paymentStatus}
            setPaymentStatus={setPaymentStatus}
            confirmPayment={confirmPaymentQR}
            deposit={depositPrice}
            setDeposit={setDepositPrice}
            data={data}
            car={car}
            step={step}
            setStep={setStep}
          />
        );
      case "booking_success":
        return (
          <RentModalMobilePaymentSuccess
            closeFunc={() => setActive(false)}
            car={car}
          />
        );
      case "booking_result":
        return (
          <RentModalMobilePaymentResult
            closeFunc={() => setActive(false)}
            car={car}
            paymentStatus={paymentStatus}
          />
        );
    }
  };

  if (!active) {
    return <></>;
  }

  return (
    <div className={`mobile-modal active`}>
      <div className="mobile-modal_header">
        <div className="mobile-modal_header-top">
          <img src={back} onClick={() => setActive(false)} alt="" />
          <HeaderLogoImage width={"100px"} height={"24px"} image="light" />
          <img src={call} alt="" />
        </div>
        <div className="mobile-modal_header-bottom">
          <h3>
            Доступные <br /> автомобили
          </h3>
          <p>
            С нами просто. <br /> Индивидуальные решения для вас
          </p>
        </div>
      </div>
      <div className="mobile-modal_body">
        <h1>
          Бронирование <br />{" "}
          <span>{carName || car.brand + " " + car.model}</span>
        </h1>
        {renderSteps()}
      </div>
      <div className=" personal-account_footer mobile-modal_footer ">
        ООО ВОСХОДⓒ 2023 год
      </div>
    </div>
  );
};
