import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import arrowImageBlack from "../../../images/index/gray-arrow.svg";
import arrowImageGray from "../../../images/index/arrow-gray.svg";
import Animator from "../../../Animator";
import { useNavigate } from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import CatalogService from "../../../api-functions/catalog-page/catalog-service";
import CarCard from "../../common/CarCard/CarCard";
import {CarCatalogDataInfo} from "../../../types/CatalogTypes";

const IndexGreatDeals = () => {
	const  {data: bestCardResponse, isLoading, error, isError} = useQuery({
		queryKey: ["best-cars"],
		queryFn: async () => await CatalogService.getBestCars()
	})

	const navigate = useNavigate();
	useEffect(() => {
		Animator.animateOnShow(
			"great-deal_cars",
			[
				{ id: "great-deal_car1", delay: 100 },
				{ id: "great-deal_car2", delay: 600 },
				{ id: "great-deal_car3", delay: 1200 },
				{ id: "great-deal_car4", delay: 1800 },
			],
			false
		);
	}, []);

	return (
		<div className="great-deal">
			<Container fluid={"xxl"}>
				<div className={"great-deal_header"}>
					<h1>выгодные предложения</h1>
						<div className="great-deal_more" onClick={() => navigate("/catalog")}>
							<p>Другие автомобили</p>

							<img
								src={arrowImageBlack}
								className="great-deal_more-black"
								alt="push"
							/>
							<img
								src={arrowImageGray}
								className="great-deal_more-gray"
								alt="push"
							/>

						</div>
				</div>
				<div className="great-deal_cars-container">
					<Row className="great-deal_cars gx-10">
						{bestCardResponse?.data.list.map((car: CarCatalogDataInfo, i: number) => (
							<Col className="d-flex justify-content-center" key={i} xs={3}>
								<CarCard
									id={"great-deal_car1"}
									car={car}
									/>
							</Col>
						))}
					</Row>
				</div>
			</Container>
		</div>
	);
};

export default IndexGreatDeals;
